import React, { useEffect, useState } from "react";
import "./cliams.css";
import "./askexpert.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid } from "@mui/material";
import Modal from "react-modal";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { get_claims } from "../utils/ExportAPI";
const Claims = () => {
  const User = useSelector((state) => state.user);
  const [tabactive, setTabactive] = useState("health");
  const [clmmodal, setClmmodal] = useState(false);
  const [itemdata, setItemdata] = useState(null);

  const DownloadPDFfile = (url) => {
    // const url = "https://www.africau.edu/images/default/sample.pdf";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", true);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };
  useEffect(() => {
    get_claims(User.userid);
  }, [])
  
  return (
    <div style={{ overflow: "hidden" }}>
      <Header backname={"Home"} pagename={"Claim"} to={"/homepage"} />

      <h3 style={{ textAlign: "center" }}>Insurance Companies - Claim</h3>
      {/* <div className="maidiv">
        <div>
          <button
            onClick={() => setTabactive("health")}
            className="clabttn"
            style={{
              backgroundColor: tabactive == "health" ? "#ffcb2b" : "#fff",
              border: "1px solid #ffcb2b",
              color: tabactive == "health" ? "#fff" : "#000",
            }}
          >
            Health Insurance
          </button>
          <p
            className="abustyle"
            style={{
              border:
                tabactive == "health" ? "8px solid #ffcb2b" : "8px solid #fff",
              display: tabactive == "health" ? "block" : "none",
            }}
          />
        </div>
        <div>
          <button
            onClick={() => setTabactive("motor")}
            className="clabttn"
            style={{
              backgroundColor: tabactive == "motor" ? "#ffcb2b" : "#fff",
              border: "1px solid #ffcb2b",
              color: tabactive == "motor" ? "#fff" : "#000",
            }}
          >
            Motor Insurance
          </button>
          <p
            className="abustyle"
            style={{
              border:
                tabactive == "motor" ? "8px solid #ffcb2b" : "8px solid #fff",

              display: tabactive == "motor" ? "block" : "none",
            }}
          />
        </div>
        <div>
          <button
            onClick={() => setTabactive("life")}
            className="clabttn"
            style={{
              backgroundColor: tabactive == "life" ? "#ffcb2b" : "#fff",
              border: "1px solid #ffcb2b",
              color: tabactive == "life" ? "#fff" : "#000",
            }}
          >
            Life Insurance
          </button>
          <p
            className="abustyle"
            style={{
              border:
                tabactive == "life" ? "8px solid #ffcb2b" : "8px solid #fff",

              display: tabactive == "life" ? "block" : "none",
            }}
          />
        </div>
      </div> */}
      <div className="container" style={{ marginTop: 30 }}>
        {User?.ClaimData?.length >= 1 ? (
          <Row>
            {User?.ClaimData?.map((v, i) => (
              <Col xs={12} md={3}>
                <ListItem
                  onClick={() => {
                    setItemdata(v);
                    setClmmodal(true);
                  }}
                  src={v.image}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <h3 className="emtclass">Add Claim Company</h3>
        )}
      </div>

      <br />
      <div
        style={{ marginTop: User?.ClaimData?.length >= 3 ? "30%" : 10 }}
        className="fotyfix"
      >
        <Footer className={"foterview"} />
      </div>

      <Modal
        isOpen={clmmodal}
        style={{ padding: "50px 20px," }}
        // onRequestClose={() => setDocmodal(false)}
        className="modalquote"
      >
        <text className="modalhead">Claim</text>
        <CancelIcon
          onClick={() => setClmmodal(false)}
          style={{
            color: "red",
            marginLeft: "auto",
            fontSize: "25px",
            textAlign: "right",
            display: "block",
            marginTop: -30,
            marginBottom: 15,
          }}
        />
        <from>
          {/* <br /> */}
          <img
            src={itemdata?.image}
            className="climg"
            style={{
              width: "50%",
              height: "auto",
              margin: "auto",
              display: "block",
            }}
          />
          <a href={"tel:"+itemdata?.contact} className="dd_flxo">
            <PhoneInTalkIcon
              style={{
                color: "green",
                marginRight: 10,
                fontSize:22,marginTop:-10
              }}
            />
            {itemdata?.contact}
          </a>
          <a
            onClick={() => DownloadPDFfile(itemdata?.health)}
            style={{ cursor: "pointer" }}
            className="dd_flxo"
          >
            <Diversity1Icon
              style={{
                color: "#e74c3c",
                marginRight: 10,

                fontSize:22,marginTop:-10
              }}
            />
            Health insurance
          </a>
          <a
            onClick={() => DownloadPDFfile(itemdata?.motor)}
            style={{ cursor: "pointer" }}
            className="dd_flxo"
          >
            <MinorCrashIcon
              style={{
                color: "#3498db",
                marginRight: 10,
                fontSize:22,marginTop:-10
              }}
            />
            Motor insurance
          </a>

          {/* <Fileditem
            labelname={"Title"}
            // setvale={setName}
            // value={name}
            placeholder={"Title"}
          /> */}

          {/* <br />
          <br />
          <button
            onClick={() => setClmmodal(false)}
            type={"submit"}
            className="viewbttn"
          >
            Submit
          </button>
          <br /> */}
        </from>
      </Modal>
    </div>
  );
};
const ListItem = ({ onClick, src }) => (
  <Grid item xs={12} md={2}>
    <a
      href="#"
      //   onClick={() => setClmmodal(true)}
      onClick={onClick}
    >
      <img
        src={src || require("../assets/images/aegon.png")}
        className="climg"
        style={{ width: "100%", height: "120px" }}
      />
      <br/>
    </a>
  </Grid>
);
const Fileditem = ({ setvale, labelname, value, placeholder, type, style }) => (
  <div className="mainfilecc">
    <text className="lablet">{labelname + "*"}</text>
    <input
      required={true}
      style={style}
      type={type || "text"}
      className="homefiled"
      placeholder={placeholder || " Full Name"}
      value={value}
      onChange={(e) => setvale(e.target.value)}
    />
  </div>
);
export default Claims;
