import React from "react";

const Errortext = ({ title,marginTop }) => {
  return (
    <text
      style={{
        color: "red",
        fontSize: 12,
        marginTop: marginTop  ||"-6px",
        display: "block",
        marginBottom: 10,
      }}
    >
      {title}
    </text>
  );
};

export default Errortext;
