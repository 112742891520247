import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logoimg from "../assets/icon/Logo.png";
import { Breadcrumbs, Typography } from "@mui/material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ShieldIcon from "@mui/icons-material/Shield";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Link } from "react-router-dom";
import store from "../redux/Store";
import { USER } from "../redux/actions/ActionType";
import Cookies from "universal-cookie";
const Header = ({ Breadlinks, to, backname, pagename }) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [notiview, setNotiview] = useState(false);
  const [settingview, setSettingview] = useState(false);
  const Notydata = [0, 1, 2, 3, 4];
  useEffect(() => {
    setNotiview(false);
    setSettingview(false);
  }, []);
  return (
    <div>
      <div className="headerf">
        <img
          onClick={() => navigate("/homepage")}
          src={logoimg}
          alt="insugoimg"
          className="logoh"
        />
        <div style={{ margin: "0px 0px", display: "flex" }}>
          <div>
            <CircleNotificationsIcon
              onClick={() => {
                setSettingview(false);
                setNotiview(!notiview);
              }}
              style={{ color: "white", marginRight: 20, fontSize: "35px" }}
            />
            <p className="coutynoty">5</p>
          </div>
          <SettingsIcon
            onClick={() => {
              setSettingview(!settingview);
              setNotiview(false);
            }}
            style={{ color: "white", marginRight: "0px", fontSize: "35px" }}
          />
        </div>
      </div>
      {notiview && (
        <div className="dropdiv">
          {Notydata?.map((v, i) => (
            <Link
              to="/notificationdetail"
              style={{ textDecorationLine: "none" }}
            >
              <div className="notibg">
                <div className="divflex">
                  <div>
                    <NotificationsIcon
                      style={{
                        color: "green",
                        marginBlock: "-2px",
                        fontSize: "18px",
                      }}
                    />
                    <text className="notytext">Alert</text>
                  </div>
                  <text className="notytext">10 min ago</text>
                </div>
                <text className="notydic">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry Lorem Ipsum.{" "}
                </text>
              </div>
            </Link>
          ))}
          <button
            onClick={() => navigate("/notificationlist")}
            className="viewbttn"
          >
            View all
          </button>
        </div>
      )}
      {settingview && (
        <div className="dropsetting">
          <ListItem TitleV={"Profile"} href={"/profile"} />

          <ListItem
            // onClick={() => {
            //   navigate("/claims");
            // }}
            href={"/claims"}
            TitleV={"Claims"}
            IconV={
              <GppMaybeIcon
                style={{
                  marginBlock: "-8px",
                  fontSize: "23px",
                  marginRight: 5,
                }}
              />
            }
          />

          <ListItem
            colormain={"blue"}
            TitleV={"Archived policies"}
            IconV={
              <ArchiveIcon
                style={{
                  marginBlock: "-8px",
                  fontSize: "23px",
                  marginRight: 5,
                }}
              />
            }
          />

          <ListItem
            href={"/askexpert"}
            colormain={"gray"}
            TitleV={"Ask an expert"}
            IconV={
              <ContactSupportIcon
                style={{
                  marginBlock: "-8px",
                  fontSize: "23px",
                  marginRight: 5,
                }}
              />
            }
          />

          <ListItem
            href={"/askexpert"}
            TitleV={"Buy a policy"}
            colormain={"#ffcb29"}
            IconV={
              <ShieldIcon
                style={{
                  marginBlock: "-6px",
                  fontSize: "23px",
                  marginRight: 5,
                }}
              />
            }
          />

          <ListItem
            TitleV={"Share app"}
            colormain={"green"}
            IconV={
              <MobileScreenShareIcon
                style={{
                  marginBlock: "-6px",
                  fontSize: "23px",
                  marginRight: 5,
                }}
              />
            }
          />

          <a
            // type="button"
            //href={"/"}
            onClick={() => {
              cookies.set("UserID", "null");
              store.dispatch({
                type: USER,
                payload: {
                  login: false,
                },
              });
              navigate("/", { replace: true });
            }}
            style={{
              marginTop: 1,
              textDecorationLine: "none",
              border: "none",
              background: "#fff",
              color: "#c00",
              display: "block",
              padding: 5,
            }}
          >
            <LoginIcon
              style={{
                marginBlock: "-4px",
                fontSize: "23px",
                marginRight: 5,
              }}
            />
            <text style={{}} className="notytext">
              Logout
            </text>
          </a>
        </div>
      )}
      <br />
      <br />
      <br />

      <div className="bacdclsdd">
        {Breadlinks || (
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to={to}>
              {backname}
            </Link>

            <Typography color="text.primary">{pagename}</Typography>
          </Breadcrumbs>
        )}
      </div>
    </div>
  );
};

const ListItem = ({ IconV, href, TitleV, colormain }) => (
  <a
    // type="button"
    // onClick={onClick}
    href={href}
    style={{
      marginTop: 5,
      border: "none",
      background: "#fff",
      color: colormain || "#000",
      textDecorationLine: "none",
      display: "block",
      padding: 5,
    }}
  >
    {IconV || (
      <AccountCircleIcon
        style={{
          marginBlock: "-4px",
          fontSize: "25px",
          marginRight: 5,
        }}
      />
    )}
    <text className="notytext">{TitleV}</text>
  </a>
);
export default Header;
