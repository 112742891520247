import React, { useEffect, useState, useInterval } from "react";
import "./Sendotp.css";
import logoimg from "../assets/icon/Logo.png";
import CustomeButton from "../components/CustomeButton";
import OtpInput from "react-otp-input";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorToast, SuccessToast } from "../utils/constant";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate } from "react-router-dom";
import Errortext from "../components/Errortext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Inputfiled from "../components/Inputfiled";
import logkay from "../assets/images/bbinsugo.png";
import { useDispatch, useSelector } from "react-redux";
import { USER } from "../redux/actions/ActionType";
import {
  Push_TokenAPI,
  get_claims,
  get_doclist,
  get_polices,
  get_profile,
} from "../utils/ExportAPI";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";
import Cookies from "universal-cookie";
const Sendotp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const User = useSelector((state) => state.user);
  const [otp, setOtp] = useState("");
  const [mobile, setMobile] = useState("");
  const [otpview, setOtpview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [veriload, setVeriload] = useState(false);
  const [password, setPassword] = useState("");
  // const [showpass, setShowpass] = useState(false);
  const [timer, setTimer] = useState(0);
  const [showpass, setShowpass] = useState(false);
  // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const [passerroe, setPasserroe] = useState(false);
  const [mobileerroe, setMobileerroe] = useState(false);
  const passwordRegex = /^(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/;

  const handleChange = (event) => {
    setMobile(event.target.value);
  };
  async function NotyPermission(userID) {
    const permission = await Notification.requestPermission();
    if (permission == "granted") {
      //generate tokan
      const token = await getToken(messaging, {
        vapidKey:
          "BASEmvZNPVBfJPH4doO-Xiy6B1S7TuuftRRDO0xxJDMPkyNhmohS_NCCxOT8POXkLDyTS49xH26Vo7PM_9as1S4",
      });
      Push_TokenAPI(userID, token);
    } else if (permission == "denied") {
      alert("permission denid");
    }
  }
  async function Validation() {
    if (mobile.length >=1) {
      setMobileerroe(false);
      if (passwordRegex.test(password)) {
        setPasserroe(false);
        LoginAPI();
      } else {
        setPasserroe(true);
      }
    } else {
      setMobileerroe(true);
    }
  }
  async function LoginAPI() {
    try {
      setVeriload(true);
      const url = "https://insugo.vensframe.com/api/login.php";
      const body = new FormData();
      body.append("contact", mobile);
      body.append("password", password);
      body.append("platform", "Web");
      // body.append("type", "Web");
      const res = await fetch(url, {
        method: "Post",
        body: body,
      });
      const rslt = await res.json();
      // console.log("login data=====>", rslt);
      if (rslt.success === true) {
        setVeriload(false);
        setOtp("");
        SuccessToast(rslt.message);
        dispatch({
          type: USER,
          payload: {
            // userid: Encode(res.data?.userid,),
            userid: rslt.userid,
            login: true,
          },
        });
        get_polices(rslt.userid);
        get_doclist(rslt.userid);
        get_profile(rslt.userid);
        get_claims(rslt.userid);
        NotyPermission(rslt.userid);
        cookies.set("UserID", rslt.userid);
        navigate("/homepage", { replace: true });
      } else {
        setVeriload(false);
        ErrorToast(rslt.message);
      }
    } catch (e) {
      console.log(e);
    }
  }
  function startCountdown(seconds) {
    const interval = setInterval(() => {
      setTimer(seconds);
      console.log(seconds);
      seconds--;
      if (seconds < 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
  useEffect(() => {
    cookies.get("UserID") == User?.userid && navigate("/homepage", { replace: true });
  }, []);
// console.log('cookies.get("UserID") != null',cookies.get("UserID") != null);
  return (
    <div className="container-fluid">
      <div className="leftycont">
        <img src={logkay} className="logyfyy" />
        <h2
          style={{
            fontSize: 50,
            marginBottom: 15,
            marginTop: 0,
            fontFamily: "inherit",
          }}
        >
          Enjoy risk-free life
        </h2>
        <text>at every stage, Let Insurance cover them.</text>
      </div>
      <div className="LoginSignUpBox" style={{ minHeight: "45vh" }}>
        {/* <div className="imgdiv">
          <img src={logoimg} alt="insugoimg" className="imageSet" />
        </div> */}
        {/* {otpview === false ? ( */}
        <div className="formdiv">
          <img src={logoimg} alt="insugoimg" className="imagemobi" />
          <h4 className="text1">login</h4>

          <Inputfiled
            placeholder="Mobile no./Email ID"
            value={mobile}
            onChange={handleChange}
          />
          {mobileerroe && (
            <Errortext marginTop={5} title={"Invalid Mobile no./Email ID!"} />
          )}
          <div style={{ display: "flex" }}>
            <Inputfiled
              security={showpass ? false : true}
              type={showpass ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <input
              security={showpass ? false : true}
              type={showpass ? "text" : "password"}
              className="inputset"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
            {showpass ? (
              <VisibilityOffIcon
                onClick={() => {
                  setShowpass(false);
                }}
                className="icoshow23"
              />
            ) : (
              <VisibilityIcon
                onClick={() => {
                  setShowpass(true);
                }}
                className="icoshow23"
              />
            )}
          </div>
          {passerroe && (
            <Errortext
              marginTop={5}
              title={
                "Password must be at least 8 characters long and contain at least  one special character !"
              }
            />
          )}
          {/* <div>
            <input type={'checkbox'} style={{width:15,height:15}} />
          <text>Accept the terms and conditions</text>
          </div>   */}
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <CustomeButton
              disabled={loading}
              // onClickHandler={() => GetOtp_API()}
              onClickHandler={() => Validation()}
              buttonname={
                loading == false ? (
                  "Login"
                ) : (
                  <ClipLoader color={"#fff"} loading={loading} size={12} />
                )
              }
            />
          </div>
          <Link className="linkcalss" to={"/singup"}>
            Create Account?
          </Link>
        </div>
        {/* ) : (
          <div className="formdiv">
            <img src={logoimg} alt="insugoimg" className="imagemobi" />
            <h4 className="text1"> User login</h4>
            <input
              // disabled={true}
              className="inputset"
              placeholder="Mobile no./Email ID"
              value={mobile}
              onChange={handleChange}
              style={{ backgroundColor: "#eee" }}
            />


            <div className="otpdicer">
              <OtpInput
                inputType="tel"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                shouldAutoFocus
                inputStyle={{
                  width: 28,
                  height: 28,
                  textAlign: "center",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  margin: "auto",
                  borderRadius: 5,
                }}
                renderSeparator={<span className="spaerate"></span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="flexclass">
              <div
                style={{
                  textAlign: "center",
                  marginTop: 20,
                  marginRight: "20px",
                }}
              >
                <CustomeButton
                  disabled={veriload}
                  onClickHandler={() => verifyOtp_API()}
                  buttonname={
                    veriload == false ? (
                      "Login"
                    ) : (
                      <ClipLoader color={"#fff"} loading={true} size={12} />
                    )
                  }
                />
              </div>
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <CustomeButton
                  disabled={timer == 0 ? false : true}
                  onClickHandler={() => GetOtp_API()}
                  buttonname={
                    loading == false ? (
                      "Resend OTP " + (timer == 0 ? "" : "(" + timer + ")")
                    ) : (
                      <ClipLoader color={"#fff"} loading={loading} size={12} />
                    )
                  }
                />
              </div>
            </div>
          </div> */}
        {/* )} */}
      </div>
      <ToastContainer />
    </div>
  );
};
export default Sendotp;
