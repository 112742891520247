import React from "react";

const Footer = ({className,textclass}) => {
  return (
    <div className={className}>
      <text className={textclass}>© 2023 Insugo</text>
    </div>
  );
};

export default Footer;
