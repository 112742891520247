import React from "react";
import { ClipLoader } from "react-spinners";
import ReactiveButton from "reactive-button";

const CustomeButton = ({
  onClickHandler,
  state,
  loadingText,
  successText,
  buttoncolor,
  buttontype,
  buttonname,height,disabled,textTcolor,fontSize,size
}) => {
  return (
    <ReactiveButton
    disabled={disabled}
      shadow
      // rounded={10}
      type={buttontype || "submit"}
      height={height || 32}
      buttonState={state}
      style={{ fontWeight: "bold", fontSize:fontSize|| 15 ,color: textTcolor ||'#fff',borderRadius:5 }}
      size={size || "medium"}
      idleText={buttonname}
      loadingText={loadingText || "Submit"}
      successText={successText || "Submit"}
      onClick={onClickHandler}
      color={buttoncolor || 'yellow'}
    />
  );
};

export default CustomeButton;
