import { getMessaging } from "firebase/messaging";
import { initializeApp } from 'firebase/app';
var firebaseConfig = {
    apiKey: "AIzaSyDhk63TXpNuvgjfHpll-QFO2zlEOa9EOiE",
    authDomain: "insugo-digi.firebaseapp.com",
    projectId: "insugo-digi",
    storageBucket: "insugo-digi.appspot.com",
    messagingSenderId: "419892778919",
    appId: "1:419892778919:web:ee64d12e02f91105738882",
    measurementId: "G-GJMBRCEHWK",
};

// initializeApp(firebaseConfig);
export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

