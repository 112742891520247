
import React from 'react'

const Inputfiled = ({disabled,value,onChange,placeholder,type,required}) => {
  return (
    <input
    disabled={disabled}
  required={required}
                type={type}
                className="inputset"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                
              />
  )
}

export default Inputfiled;