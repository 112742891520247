import React, { useEffect, useState } from "react";
import Policyimg from "../assets/images/policyIMG.jpeg";
import "./Home.css";
import Footer from "../components/Footer";
import { Grid } from "@mui/material";
import Modal from "react-modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Tesseract from "tesseract.js";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import moment from "moment";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import { RWebShare } from "react-web-share";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { SuccessToast } from "../utils/constant";
import { useSelector } from "react-redux";
import {
  QuoteAPI,
  delete_Document,
  delete_polices,
  get_doclist,
  get_polices,
} from "../utils/ExportAPI";
import { ToastContainer } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import Errortext from "../components/Errortext";
import { ClipLoader } from "react-spinners";
import Cookies from "universal-cookie";
const Homepage = () => {
  const navigate = useNavigate();
  const User = useSelector((state) => state.user);
  const cookies = new Cookies();
  // console.log("User", User?.userid);
  const [notiview, setNotiview] = useState(false);
  const [settingview, setSettingview] = useState(false);
  const [healthtab, setHealthtab] = useState(false);
  const [motortab, setMotortab] = useState(false);
  const [hometab, setHometab] = useState(false);
  const [docmodal, setDocmodal] = useState(false);
  const [doctype, setDoctype] = useState("");
  const [addarimg, setAddarimg] = useState(null);
  const [pdfadhar, setPdfadhar] = useState(null);
  const [panimg, setPanimg] = useState(null);
  const [rcimg, setRcimg] = useState(null);
  const [dlimg, setDlimg] = useState(null);
  const [vehicleimg, setVehicleimg] = useState(null);
  const [healthimg, sethealthimg] = useState(null);
  const [ocrdata, setOcrdata] = useState([]);
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(new Date());
  const [day30, setDay30] = useState(new Date());
  const [quotemodal, setQuotemodal] = useState(false);
  const [policylistimg, setPolicylistimg] = useState("");
  const [docdeletemodeal, setDocdeletemodeal] = useState(false);
  const [docidd, setDocidd] = useState("");
  const [text, setText] = useState("");
  const currentDate = new Date();
  const next30days = new Date();
  next30days.setDate(next30days.getDate() + 30);
  const next30Date = new Date();
  next30Date.setDate(next30Date.getDate() + 1);
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
  const Afterthreeyear = new Date();
  Afterthreeyear.setMonth(Afterthreeyear.getMonth() + 24);
  const [pdfData, setPdfData] = useState(null);
  const [policyviewmodal, setPolicyviewmodal] = useState(false);
  const [name, setName] = useState(User?.Profiledata?.Name);
  const [email, setEmail] = useState(User?.Profiledata?.Email);
  const [pnumber, setPnumber] = useState(User?.Profiledata?.mobile);
  const [messageQ, setMessageQ] = useState("");
  const [showhide, setShowhide] = useState(false);
  const [filactive, setFilactive] = useState("");
  const [deletemodal, setDeletemodal] = useState(false);
  const [quidd, setQuidd] = useState("");
  const [qdoctype, setQdoctype] = useState("");
  const [qmessageerroe, setQmessageerroe] = useState(false);
  const [delID, setDelID] = useState("");
  const [qload, setQload] = useState(false);
  const DownloadPDFfile = (pass) => {
    const url = pass;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", true);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };
  function Addsubmit() {
    setDocmodal(false);
    setDoctype("");
  }
  function OCRfun() {
    if (addarimg != null) {
      Tesseract.recognize(addarimg, "eng", {
        logger: (m) => console.log(m),
      }).then(({ data: { text } }) => {
        setOcrdata(text);
      });
    }
  }

  function ExistQuoteAPI() {
    if (messageQ.length >= 10) {
      setQmessageerroe(false);
      setQload(true);
      QuoteAPI(User?.userid, qdoctype, name, email, pnumber, quidd, messageQ)
        .then((data) => {
          setQload(true);
          SuccessToast("Our team Contact you very soon");
          setQuotemodal(false);
        })
        .catch((e) => console.log(e));
    } else {
      setQmessageerroe(true);
    }
  }
  useEffect(() => {
    get_polices(User?.userid);
    get_doclist(User?.userid);
    setNotiview(false);
    setSettingview(false);
    //  ram.map((v,i))
  }, []);
  return (
    <div className="homediv">
      <Header Breadlinks />
      <div className="flexview345" style={{ marginTop: -20 }}>
        <h2 className="h2style" style={{ marginBottom: 10 }}>
          Existing Policies
        </h2>
        <div style={{ margin: "0px 25px -14px 25px" }}>
          {User?.policyData?.length >= 1 && (
            <button
              onClick={() => {
                navigate("/addpolicy");
              }}
              className="viewbttn"
            >
              Add Policy
            </button>
          )}
        </div>
      </div>
      <div className="textpolity">
        <div style={{ width: "97%", display: "block", margin: "auto" }}>
          {User?.policyData?.length >= 1 && (
            <div style={{ marginBottom: "10px" }}>
              <Button
                style={{ fontSize: 13, marginRight: 10 }}
                onClick={() => setFilactive("showall")}
                active={filactive == "showall" ? true : false}
                variant="outline-primary"
              >
                Show All
              </Button>
              <Button
                style={{ fontSize: 13, marginRight: 10 }}
                onClick={() => setFilactive("health")}
                active={filactive == "health" ? true : false}
                variant="outline-primary"
              >
                Health
              </Button>
              <Button
                style={{ fontSize: 13, marginRight: 10 }}
                onClick={() => setFilactive("motor")}
                active={filactive == "motor" ? true : false}
                variant="outline-primary"
              >
                Motor
              </Button>
              <Button
                style={{ fontSize: 13, marginRight: 10 }}
                onClick={() => setFilactive("life")}
                active={filactive == "life" ? true : false}
                variant="outline-primary"
              >
                Life
              </Button>
              <Button
                style={{ fontSize: 13, marginRight: 10 }}
                onClick={() => setFilactive("other")}
                active={filactive == "other" ? true : false}
                variant="outline-primary"
              >
                Others
              </Button>
            </div>
          )}

          {User?.policyData?.length >= 1 ? (
            <Table bordered={true} responsive={true} align="center">
              <thead
                style={{ background: "#727b82", color: "#fff", fontSize: 15 }}
              >
                <tr>
                  {/* <th className="tabletext">Holder</th>
                <th className="tabletext">Product</th> */}
                  <th style={{ width: 150 }} className="tabletext">
                    Name
                  </th>
                  <th className="tabletext">Company</th>
                  <th className="tabletext">Insurance</th>
                  <th className="tabletext">Plan/Registration</th>
                  <th className="tabletext">Policy no.</th>
                  <th className="tabletext">Expiry Date</th>
                  <th className="tabletext">Action</th>
                  <th className="tabletext">Quote</th>
                </tr>
              </thead>
              <tbody>
                {User?.policyData?.map((v, i) => (
                  <tr
                    style={{
                      backgroundColor: v?.get == true ? "#ffcb293d" : "#fff",
                    }}
                  >
                    {/* <td className="tabletext">{v.name}</td> */}
                    {/* <td className="tabletext">{v.product}</td> */}
                    <td style={{ width: 150 }} className="tabletext">
                      {v?.name}
                    </td>
                    <td className="tabletext">{v?.policy_company}</td>
                    <td className="tabletext">{v?.insurance_type}</td>
                    <td className="tabletext">{v?.policy_plan}</td>
                    <td className="tabletext">{v?.policy_number}</td>
                    <td className="tabletext">{v?.expiry_date}</td>
                    <td>
                      <div>
                        <RemoveRedEyeIcon
                          onClick={() => {
                            setPolicyviewmodal(true);
                            setPolicylistimg(v?.policy_file);
                          }}
                          className="actioncion"
                          style={{ color: "#15aac8" }}
                        />
                        <BorderColorIcon
                          onClick={() =>
                            navigate("/Updatepolicy", { state: { v } })
                          }
                          className="actioncion"
                          style={{ color: "green" }}
                        />
                        <RWebShare
                          data={{
                            text: "Insugo Insurance ",
                            url: v?.policy_file,
                            title: "Insugo",
                          }}
                          onClick={() => console.log("shared successfully!")}
                        >
                          <ShareIcon
                            className="actioncion"
                            style={{ color: "#000" }}
                          />
                        </RWebShare>
                        <DownloadIcon
                          onClick={() => DownloadPDFfile(v?.policy_file)}
                          className="actioncion"
                          style={{ color: "#2238c7" }}
                        />
                        <DeleteOutlineIcon
                          onClick={() => {
                            setDelID(v?.id);
                            setDeletemodal(true);
                          }}
                          className="actioncion"
                          style={{ color: "#c00" }}
                        />
                      </div>
                    </td>
                    <td className="tabletext">
                      {v?.expiry_date <=
                        moment(next30days).format("YYYY-MM-DD") && (
                        <div style={{ margin: "0px 0px" }}>
                          <button
                            style={{ display: "initial" }}
                            onClick={() => {
                              setQuidd(v?.id);
                              setQdoctype(v?.insurance_type);
                              setQuotemodal(true);
                            }}
                            className="viewbttn"
                          >
                            Get a Quote
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h3 style={{ width: "100%" }} className="emtclass">
              No policy,
              <text
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                  marginLeft: 10,
                }}
                onClick={() => {
                  navigate("/addpolicy");
                }}
              >
                add a policy
              </text>
            </h3>
          )}
        </div>
      </div>

      <div className="flexclassh">
        <h2 className="h2style">Documents</h2>
        <div style={{ margin: "0px 25px" }}>
          {User?.DocListData?.length >= 1 && (
            <button
              onClick={() => {
                navigate("/adddocument");
              }}
              className="viewbttn"
            >
              Add New
            </button>
          )}
        </div>
      </div>

      {User?.DocListData?.length >= 1 ? (
        // <Grid container spacing={2}>
        <Row>
          {User?.DocListData?.map((v, i) => (
            <Col xs={12} md={3}>
              <DocumentItem
                onClick={() => {
                  navigate("/Updatedocument", { state: { v } });
                }}
                DeleteClick={() => {
                  setDocidd(v?.id);
                  setDocdeletemodeal(true);
                }}
                title={v?.Name}
                typename={v?.doc_type + " Card"}
                imgsrc={
                  v?.document.split(".")?.at(-1) == "pdf"
                    ? "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
                    : v?.document
                }
              />
            </Col>
          ))}
        </Row>
      ) : (
        // </Grid>
        <h3 className="emtclass">
          No document,
          <text
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
              marginLeft: 10,
            }}
            onClick={() => {
              navigate("/adddocument");
            }}
          >
            add a document
          </text>
        </h3>
      )}

      <text
        style={{
          textAlign: "center",
          fontSize: 16,
          fontWeight: "normal",
          display: "block",
          marginTop: 20,
          color: "black",
        }}
      >
        <text
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={() => navigate("/askexpert")}
        >
          Click here
        </text>{" "}
        to buy new policy
      </text>
      <div
        style={{ marginTop: User?.DocListData?.length == 0 ? "12.5%" : 10 }}
      />
      <Footer className={"foterview"} />
      <Modal isOpen={docmodal} className="modalcont">
        <text className="modalhead">Add Document (Image/PDF)</text>
        <CancelIcon
          onClick={() => Addsubmit()}
          style={{
            color: "red",
            marginLeft: "auto",
            fontSize: "25px",
            textAlign: "right",
            display: "block",
            marginTop: -30,
            marginBottom: 15,
          }}
        />
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <UploadView
              selectedImage={addarimg}
              onChange={(event) => {
                setAddarimg(event.target.files[0]);
              }}
              removedata={setAddarimg}
            />
            <br />
          </Grid>
          <Grid item xs={12} md={4}>
            <UploadView
              title={"Upload PAN Card"}
              selectedImage={panimg}
              onChange={(event) => {
                setPanimg(event.target.files[0]);
              }}
              removedata={setPanimg}
            />
            <br />
          </Grid>
          <Grid item xs={12} md={4}>
            <UploadView
              title={"Upload RC Card"}
              selectedImage={rcimg}
              onChange={(event) => {
                setRcimg(event.target.files[0]);
              }}
              removedata={setRcimg}
            />
            <br />
          </Grid>
          <Grid item xs={12} md={4}>
            <UploadView
              title={"Upload DL Card"}
              selectedImage={dlimg}
              onChange={(event) => {
                setDlimg(event.target.files[0]);
              }}
              removedata={setDlimg}
            />
            <br />
          </Grid>
          <Grid item xs={12} md={4}>
            <UploadView
              title={"Upload Vehicle Insurance"}
              selectedImage={vehicleimg}
              onChange={(event) => {
                setVehicleimg(event.target.files[0]);
              }}
              removedata={setVehicleimg}
            />
            <br />
          </Grid>
          <Grid item xs={12} md={4}>
            <UploadView
              title={"Upload Health Insurance"}
              selectedImage={healthimg}
              onChange={(event) => {
                sethealthimg(event.target.files[0]);
              }}
              removedata={sethealthimg}
            />
            <br />
          </Grid>
        </Grid>

        <button onClick={() => Addsubmit()} className="viewbttn">
          Submit
        </button>
      </Modal>

      <Modal
        isOpen={quotemodal}
        // onRequestClose={() => setDocmodal(false)}
        className="modalquote"
      >
        <text className="modalhead">Get A Quote</text>
        <CancelIcon
          onClick={() => setQuotemodal(false)}
          style={{
            color: "red",
            marginLeft: "auto",
            fontSize: "25px",
            textAlign: "right",
            display: "block",
            marginTop: -30,
            marginBottom: 15,
          }}
        />

        <br />
        {/* <div className="quoteflex"> */}
        <Fileditem
          labelname={"Full Name"}
          setvale={setName}
          value={name}
          placeholder={"Full Name"}
        />
        <Fileditem
          labelname={"EmailID"}
          setvale={setEmail}
          value={email}
          placeholder={"ex: myname@example.com"}
        />
        {/* </div> */}
        {/* <div className="quoteflex"> */}
        <Fileditem
          labelname={"Phone Number"}
          setvale={setPnumber}
          value={pnumber}
          placeholder={"Phone no."}
        />

        {/* </div> */}

        <text className="lablet">Additional Infromation</text>
        <textarea
          className="messfiled"
          rows={5}
          cols={5}
          value={messageQ}
          placeholder="Additional Infromation"
          onChange={(e) => setMessageQ(e.target.value)}
        />
        {qmessageerroe && (
          <Errortext
            marginTop={5}
            title={"Additional infromation is required!"}
          />
        )}
        <br />
        <br />
        <button
          onClick={() => ExistQuoteAPI()}
          type={"submit"}
          className="viewbttn"
        >
          {qload == false ? (
            "Submit"
          ) : (
            <ClipLoader color={"#fff"} loading={qload} size={12} />
          )}
        </button>
        <br />
      </Modal>

      <Modal
        isOpen={policyviewmodal}
        // onRequestClose={() => setDocmodal(false)}
        className="modalquote"
      >
        <text className="modalhead">View Policy</text>
        <CancelIcon
          onClick={() => setPolicyviewmodal(false)}
          style={{
            color: "red",
            marginLeft: "auto",
            fontSize: "25px",
            textAlign: "right",
            display: "block",
            marginTop: -30,
            marginBottom: 15,
          }}
        />
        {policylistimg?.split(".")?.at(-1) == "pdf" ? (
          <iframe
            src={policylistimg}
            width={"100%"}
            height={500}
            title="insugo"
          ></iframe>
        ) : (
          <img src={policylistimg} alt="insugoimg" className="polycss" />
        )}
      </Modal>

      <Modal
        isOpen={deletemodal}
        // onRequestClose={() => setDocmodal(false)}

        className="modalquote"
      >
        <text
          className="modalhead"
          style={{ paddingTop: 10, fontWeight: "500" }}
        >
          Are you sure you want to delete?
        </text>
        <CancelIcon
          onClick={() => setDeletemodal(false)}
          style={{
            color: "red",
            marginLeft: "auto",
            fontSize: "25px",
            textAlign: "right",
            display: "block",
            marginTop: -30,
            marginBottom: 15,
          }}
        />
        <button
          className="viewbttn"
          style={{ backgroundColor: "#c00", marginBlock: 20 }}
          onClick={() =>
            delete_polices(User?.userid, delID)
              .then((data) => {
                SuccessToast("Delete policy successfully");
                setDeletemodal(false);
              })
              .catch((e) => console.log(e))
          }
        >
          Delete
        </button>
      </Modal>

      <Modal
        isOpen={docdeletemodeal}
        // onRequestClose={() => setDocmodal(false)}

        className="modalquote"
      >
        <text
          className="modalhead"
          style={{ paddingTop: 10, fontWeight: "500" }}
        >
          Are you sure you want to delete?
        </text>
        <CancelIcon
          onClick={() => setDocdeletemodeal(false)}
          style={{
            color: "red",
            marginLeft: "auto",
            fontSize: "25px",
            textAlign: "right",
            display: "block",
            marginTop: -30,
            marginBottom: 15,
          }}
        />
        <button
          className="viewbttn"
          style={{ backgroundColor: "#c00", marginBlock: 20 }}
          onClick={() =>
            delete_Document(User?.userid, docidd)
              .then((data) => {
                SuccessToast("Delete Document successfully");
                setDocdeletemodeal(false);
              })
              .catch((e) => console.log(e))
          }
        >
          Delete
        </button>
      </Modal>
      <ToastContainer />
    </div>
  );
};
const Fileditem = ({ setvale, labelname, value, placeholder, type, style }) => (
  <div className="mainfilecc">
    <text className="lablet">{labelname + "*"}</text>
    <input
      required={true}
      style={style}
      type={type || "text"}
      className="homefiled"
      placeholder={placeholder || " Full Name"}
      value={value}
      onChange={(e) => setvale(e.target.value)}
    />
  </div>
);
const DocumentItem = ({ title, imgsrc, typename, onClick, DeleteClick }) => (
  <div className="documentbox">
    <text
      style={{
        textAlign: "center",
        fontWeight: "bold",
        display: "block",
        marginBottom: 15,
        marginTop: 5,
      }}
    >
      {typename}
    </text>
    <img src={imgsrc} alt="insugoimg" className="doccimg" />
    <div>
      <text
        style={{
          fontWeight: "bold",
          color: "#000",
          fontSize: 15,
          paddingTop: 20,
          display: "block",
          textTransform: "capitalize",
        }}
      >
        {title}
      </text>
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: -30,
        marginBottom: -10,
      }}
    >
      <BorderColorIcon
        style={{ fontSize: 18 }}
        onClick={onClick}
        className="docedito"
      />
      <DeleteOutlineIcon
        onClick={DeleteClick}
        className="actioncion"
        style={{ color: "#c00" }}
      />
    </div>
  </div>
);
const UploadView = ({ selectedImage, title, removedata, onChange }) => (
  <div>
    <text
      style={{
        color: "#000",
        fontSize: 14,
        marginBottom: selectedImage != null ? 10 : 0,
        display: "block",
        fontWeight: "bold",
      }}
    >
      {title || "Upload Aadhar card"}
    </text>{" "}
    {selectedImage && (
      <div>
        <img
          alt="aadharcard"
          width={"50%"}
          height={"auto"}
          src={
            selectedImage?.type == "application/pdf"
              ? "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
              : URL.createObjectURL(selectedImage)
          }
        />
        <br />
        <button
          className="viewbttn33"
          style={{ backgroundColor: "red" }}
          onClick={() => removedata(null)}
        >
          Remove
        </button>
      </div>
    )}
    <br />
    <input
      type="file"
      name="myImage"
      accept="application/pdf,image/*"
      onChange={onChange}
    />
  </div>
);
export default Homepage;
