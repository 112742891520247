import store from "../redux/Store";
import { USER } from "../redux/actions/ActionType";

export const get_profile = async (userid) => {
  try {
    const body = new FormData();
    body.append("id", userid);
    body.append("action", "listbyid");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/profile.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    // console.log("get profile============>", res);
    if (res.success) {
      store.dispatch({
        type: USER,
        payload: { Profiledata: res?.message },
      });
      return res;
    } else {
      store.dispatch({
        type: USER,
        payload: { Profiledata: {} },
      });
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};
export const get_polices = async (userid) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("action", "list");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/policy.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    console.log("get policy============>", res);
    if (res.success) {
      store.dispatch({
        type: USER,
        payload: { policyData: res?.message },
      });
      return res;
    } else {
      store.dispatch({
        type: USER,
        payload: { policyData: [] },
      });
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const get_doclist = async (userid) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("action", "list");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/document.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    // console.log("get doc============>", res);
    if (res.success) {
      store.dispatch({
        type: USER,
        payload: { DocListData: res?.message },
      });
      return res;
    } else {
      store.dispatch({
        type: USER,
        payload: { DocListData: [] },
      });
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};
export const delete_polices = async (userid, id) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("id", id);
    body.append("action", "delete");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/policy.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    // console.log("delete policy============>", res);
    if (res.success) {
      get_polices(userid);
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const QuoteAPI = async (
  userid,
  intype,
  name,
  email,
  contact,
  id,
  message
) => {
  try {
    const url = "https://insugo.vensframe.com/api/contact.php";
    const body = new FormData();
    body.append("userid", userid);
    body.append("instype", intype);
    body.append("name", name);
    body.append("email", email);
    body.append("contact", contact);
    body.append("id", id);
    body.append("message", message);
    body.append("policy", "existing");
    const res = await fetch(url, {
      method: "Post",
      body: body,
    });
    const rslt = await res.json();
    console.log("contact=>", rslt);
    if (rslt.success === true) {
      get_polices(userid);
    }
  } catch (e) {
    console.log(e);
  }
};

export const QuoteBuyAPI = async (
  userid,
  intype,
  name,
  email,
  contact,
  message
) => {
  try {
    const url = "https://insugo.vensframe.com/api/contact.php";
    const body = new FormData();
    body.append("userid", userid);
    body.append("instype", intype);
    body.append("name", name);
    body.append("email", email);
    body.append("contact", contact);
    body.append("message", message);
    body.append("policy", "Buy");
    const res = await fetch(url, {
      method: "Post",
      body: body,
    });
    const rslt = await res.json();
    console.log("contact=>", rslt);
    if (rslt.success === true) {
      get_polices(userid);
    }
  } catch (e) {
    console.log(e);
  }
};
export const ADDDocumentAPI = async (userid, doctype, docname, docfile) => {
  try {
    const url = "https://insugo.vensframe.com/api/document.php";
    const body = new FormData();
    body.append("userid", userid);
    body.append("doctype", doctype);
    body.append("docname", docname);
    body.append("docfile", docfile);
    body.append("action", "create");
    const res = await fetch(url, {
      method: "Post",
      body: body,
    });
    const rslt = await res.json();
    // console.log("rslt==>", rslt);
    if (rslt.success === true) {
      get_doclist(userid);
      return res;
    } else {
      console.log(rslt);
    }
  } catch (e) {
    console.log(e);
  }
};

export const UpdateDocumentAPI = async (
  id,
  userid,
  doctype,
  docname,
  docfile
) => {
  try {
    const url = "https://insugo.vensframe.com/api/document.php";
    const body = new FormData();
    body.append("id", id);
    body.append("userid", userid);
    body.append("doctype", doctype);
    body.append("docname", docname);
    body.append("docfile", docfile);
    body.append("action", "update");
    const res = await fetch(url, {
      method: "Post",
      body: body,
    });
    const rslt = await res.json();
    // console.log("rslt==>", rslt);
    if (rslt.success === true) {
      return res;
    } else {
      console.log(rslt);
    }
  } catch (e) {
    console.log(e);
  }
};
export const delete_Document = async (userid, id) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("id", id);
    body.append("action", "delete");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/document.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    // console.log("delete policy============>", res);
    if (res.success) {
      get_doclist(userid);
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const get_claims = async (userid) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("action", "list");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/claims.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    console.log("get claims============>", res);
    if (res.success) {
      store.dispatch({
        type: USER,
        payload: { ClaimData: res?.message },
      });
      return res;
    } else {
      store.dispatch({
        type: USER,
        payload: { ClaimData: [] },
      });
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const Update_profile = async (userid, name, email) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("name", name);
    body.append("email", email);
    body.append("action", "update");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/profile.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    // console.log("profile update============>", res);
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const Update_password = async (userid, password, newpassword) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("password", password);
    body.append("newpassword", newpassword);
    body.append("action", "changepwd");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/profile.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    // console.log("profile update============>", res);
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const Update_mobile = async (
  userid,
  mobile,
  id,
  otp,
  mobile1,
  mobile2
) => {
  // console.log(userid, mobile, id, otp,mobile1,mobile2);
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("mobile", mobile);
    body.append("id", id);
    body.append("otp", otp);
    body.append("mobile1", mobile1);
    body.append("mobile2", mobile2);
    body.append("action", "updatemobile");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/profile.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    console.log("profile mobile============>", res);
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const Update_mobile1 = async (
  userid,
  mobile1,
  id,
  otp,
) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("mobile1", mobile1);
    body.append("id", id);
    body.append("otp", otp);
    body.append("action", "updatemobile");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/profile.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    console.log("profile mobile1============>", res);
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const Update_mobile2 = async (
  userid,
  mobile2,
  id,
  otp,
) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("mobile2", mobile2);
    body.append("id", id);
    body.append("otp", otp);
    body.append("action", "updatemobile");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/profile.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    console.log("profile mobile2============>", res);
    if (res.success) {
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
  }
};

export const Push_TokenAPI = async (
  userid,
  token,
) => {
  try {
    const body = new FormData();
    body.append("userid", userid);
    body.append("medium", 'web');
    body.append("token", token);
    const responce = await fetch(
      "https://insugo.vensframe.com/api/token.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    console.log("Token api============>", res);
    if (res.success) {
      return res;
    } 
  } catch (e) {
    console.log(e);
  }
};