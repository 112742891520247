import React, { useEffect } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
const Notificationlist = () => {
  const Notyraay = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8];
  useEffect(() => {
    // const  arry =[1,2,3,4,5,1,1,1];
    // console.log('array',arry.filter((v,i)=>arry.indexOf(v)==i));
  }, []);
  return (
    <div style={{ overflow: "hidden" }}>
      <Header backname={"Home"} pagename={"Notification"} to={"/homepage"} />
      <br />
      <div style={{ width: "95%", margin: "auto" }}>
        {Notyraay.map((v, i) => (
           <Link to="/notificationdetail" style={{textDecorationLine:'none'}}>
          <div className="notibg">
            <div className="divflex">
              <div>
                <NotificationsIcon
                  style={{
                    color: "green",
                    marginBlock: "-2px",
                    fontSize: "18px",
                  }}
                />
                <text className="notytext">Alert</text>
              </div>
              <text className="notytext">10 min ago</text>
            </div>
            <text className="notydic">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry Lorem Ipsum.{" "}
            </text>
          </div></Link>
        ))}
      </div>
      {/* <div className="fotyfix"> */}
      <Footer className={"foterview"} />
      {/* </div> */}
    </div>
  );
};

export default Notificationlist;
