import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Inputfiled from "../components/Inputfiled";
import "./Addpolicy.css";
import { PDFDocument, PDFImage } from "pdf-lib";
import pdfjsLib from "pdfjs-dist";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { ErrorToast, SuccessToast } from "../utils/constant";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import Errortext from "../components/Errortext";
import { get_polices } from "../utils/ExportAPI";
import CancelIcon from '@mui/icons-material/Cancel';
const Addpolicy = () => {
  const User = useSelector((state) => state.user);
  // var path = require("path");
  // var extract = require("pdf-text-extract");
  // var filePath = path.join("https://www.africau.edu/images/default/sample.pdf");
  // const url = "https://www.africau.edu/images/default/sample.pdf";
  const url = "../assets/Insugo.pdf";
  const [policytype, setPolicytype] = useState("null");
  const [insucopy, setInsucopy] = useState("");
  const [name, setName] = useState("");
  const [polycompnay, setPolycompnay] = useState("");
  const [polyplan, setPolyplan] = useState("");
  const [polynum, setPolynum] = useState("");
  const [expdate, setExpdate] = useState("");
  const [text, setText] = useState("");
  const [othersec, setOthersec] = useState("");
  const [pdfimg, setPdfimg] = useState([]);
  const [pdfbufers, setPdfbufers] = useState("");
  const [vhenumber, setVhenumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [imgerroe, setImgerroe] = useState(false);
  const [nameerroe, setNameerroe] = useState(false);
  const [companyerroe, setCompanyerroe] = useState(false);
  const [planerroe, setPlanerroe] = useState(false);
  const [polynumerroe, setPolynumerroe] = useState(false);
  const [experroe, setExperroe] = useState(false);
  const [vheerroe, setvheerroe] = useState(false);
  // console.log('insucopy',insucopy);
  const handleChange = (e) => {
    setPolicytype(e.target.value);
    setInsucopy("");
    setName("");
    setPolycompnay("");
    setPolyplan("");
    setPolynum("");
    setExpdate("");
    setVhenumber("");
  };
  function PolicyValidation() {
    if (insucopy?.length >= 1 || insucopy != "") {
      setImgerroe(false);
      if (name?.length >= 1) {
        setNameerroe(false);
        if (polycompnay?.length >= 1) {
          setCompanyerroe(false);
          if (polynum?.length >= 1) {
            setPolynumerroe(false);
            if (
              policytype == "Motor"
                ? vhenumber?.length >= 1
                : polyplan?.length >= 1
            ) {
              setPlanerroe(false);
              if (expdate?.length >= 1) {
                setExperroe(false);
                ADDpolicyAPI();
              } else {
                setExperroe(true);
              }
            } else {
              setPlanerroe(true);
            }
          } else {
            setPolynumerroe(true);
          }
        } else {
          setCompanyerroe(true);
        }
      } else {
        setNameerroe(true);
      }
    } else {
      setImgerroe(true);
    }
  }
  // console.log('text',User?.userid);
  // console.log("insucopy", URL.createObjectURL(insucopy));
  // const PDFTOTEXXT = async () => {
  //   fetch("../../../../../Downloads/Gaswale customer App.pptx.pdf")
  //     .then((response) => response.arrayBuffer())
  //     .then((buffer) => {
  //       console.log("log", buffer);
  //       setPdfbufers(buffer);
  //     })
  //     .catch((error) => {
  //       console.error("Error loading PDF file:", error);
  //     });
  // };

  // const Maincont = async () => {
  //   const pdfBuffer = pdfbufers;

  //   const pdfDoc = await PDFDocument.load(pdfBuffer);
  //   const firstPage = pdfDoc.getPages()[0];
  //   const pngImage = await PDFImage.load(await firstPage.render());
  //   const pngData = await pngImage?.pngData;
  //   console.log("====================================");
  //   console.log("pngData", pngData);
  //   console.log("====================================");
  // };

  const PDFTOTEXXT = async () => {
    try {
      const formData = new FormData();
      formData.append("pdfFile", insucopy);
      const response = await axios.post(
        "https://pdf-to-text-72wo.onrender.com/extract-text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      setText(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  function convettext(buffer) {
    pdfjsLib?.getDocument({ buffer }).promise.then((doc) => {
      doc.getPage(1).then((page) => {
        page.getTextContent().then((textContent) => {
          const text = textContent?.items.map((item) => item.str).join("");
          console.log(text);
        });
      });
    });
  }

  async function ADDpolicyAPI() {
    try {
      setLoading(true);
      const url = "https://insugo.vensframe.com/api/policy.php";
      const body = new FormData();
      body.append("userid", User?.userid);
      body.append("instype", policytype);
      body.append("holname", name);
      body.append("polcompany", polycompnay);
      body.append("polplan", polyplan);
      body.append("polnum", polynum);
      body.append("expiry", expdate);
      // body.append("policyfile", {
      //   name: insucopy?.name,
      //   type: insucopy?.type,
      //   uri: URL.createObjectURL(insucopy),
      // });
      body.append("policyfile", insucopy);
      body.append("action", "create");
      const res = await fetch(url, {
        method: "Post",
        body: body,
      });
      const rslt = await res.json();
      // console.log("rslt", rslt);
      if (rslt.success === true) {
        setLoading(false);
        get_polices(User?.userid);
        SuccessToast(rslt.message);
        setPolicytype("null");
        setInsucopy("");
        setName("");
        setPolycompnay("");
        setPolyplan("");
        setPolynum("");
        setExpdate("");
        setVhenumber("");
      } else {
        setLoading(false);
        ErrorToast(rslt.message);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    // PDFTOTEXXT();
  }, []);

  return (
    <div className="homediv">
      <Header backname={"Home"} pagename={"Add Policy"} to={"/homepage"} />

      {/* <iframe
        src={url}
        style={{ width: "100%", height: "100%", border: "none" }}
      ></iframe> */}
      {/* <img
        src={`data:image/png;base64,${btoa(
          String.fromCharCode(...new Uint8Array(pngData))
        )}`}
      /> */}
      {/* <text>{text}</text> */}

      <div className="alicenter">
        <h3 style={{ textAlign: "center" }}>Add Policy</h3>
        <text className="lablet"> Select insurance type</text>
        <select
          style={{ backgroundColor: "#fff" }}
          className="selctarea"
          value={policytype}
          onChange={handleChange}
        >
          <option value={"null"}>Select insurance</option>
          <option value={"Motor"}>Motor insurance</option>
          <option value={"Health"}>Health insurance</option>
          <option value={"Life"}>Life insurance</option>
          <option value={"Other"}>Other</option>
        </select>
        <br />
        <br />
        {policytype != "null" && (
          <div>
            {policytype == "Other" && (
              <div>
                <text className="lablet"> Select Other</text>
                <select
                  style={{ backgroundColor: "#fff" }}
                  className="selctarea"
                  value={othersec}
                  onChange={(e) => setOthersec(e.target.value)}
                >
                  <option value={"null"}>Term insurance</option>
                  <option value={"Motor"}>Life insurance</option>
                </select>
              </div>
            )}
            <br />
            <text className="lablet"> Upload insurance copy</text>
            <UploadView
              selectedImage={insucopy}
              onChange={(event) => {
                setInsucopy(event.target.files[0]);
              }}
              removedata={() => setInsucopy("")}
            />
            {imgerroe && (
              <Errortext marginTop={5} title={"insurance copy is required!"} />
            )}
            <br />
            {/* {(policytype != "Motor") && ( */}
            {/* <div>
              <text className="lablet">Product name</text>
              <Inputfiled
                placeholder="Product name"
                // value={mobile}
                // onChange={handleChange}
              />
            </div> */}
            {/* )} */}
            <div>
              <text className="lablet">Policy holder name </text>
              <Inputfiled
                placeholder="Policy name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {nameerroe && (
              <Errortext
                marginTop={5}
                title={"Policy holder name is required!"}
              />
            )}
            <div>
              <text className="lablet">Policy company</text>
              <Inputfiled
                placeholder="Policy company"
                value={polycompnay}
                onChange={(e) => setPolycompnay(e.target.value)}
              />
            </div>
            {companyerroe && (
              <Errortext marginTop={5} title={"Policy company is required!"} />
            )}
            {policytype != "Motor" && (
              <div>
                <text className="lablet">Policy Plan</text>
                <Inputfiled
                  placeholder="Plan name"
                  value={polyplan}
                  onChange={(e) => setPolyplan(e.target.value)}
                />
              </div>
            )}
            {policytype == "Motor" && (
              <div>
                <text className="lablet">Vehicel number</text>
                <Inputfiled
                  placeholder="Vehicel number"
                  value={vhenumber}
                  onChange={(e) => setVhenumber(e.target.value)}
                />
              </div>
            )}
            {planerroe && (
              <Errortext
                marginTop={5}
                title={
                  policytype == "Motor"
                    ? "Vehicel number is required!"
                    : "Plan name is required!"
                }
              />
            )}
            <div>
              <text className="lablet">Policy number</text>
              <Inputfiled
                placeholder="Policy no."
                value={polynum}
                onChange={(e) => setPolynum(e.target.value)}
              />
            </div>
            {polynumerroe && (
              <Errortext marginTop={5} title={"Policy number is required!"} />
            )}
            <div>
              <text className="lablet">Expiry Date</text>
              <Inputfiled
                type={"date"}
                placeholder="Expiry Date"
                value={expdate}
                onChange={(e) => setExpdate(e.target.value)}
              />
            </div>
            {experroe && (
              <Errortext marginTop={5} title={"Expiry Date is required!"} />
            )}
            <br /> <br />
            <button
              type="submit"
              onClick={() => PolicyValidation()}
              className="viewbttn"
            >
              {loading == false ? (
                "Add"
              ) : (
                <ClipLoader color={"#fff"} loading={loading} size={12} />
              )}
            </button>
            {/* <input
                type="submit"
                value={
                  loading == false ? (
                    "Add"
                  ) : (
                    <ClipLoader color={"#fff"} loading={loading} size={12} />
                  )
                }
                className="viewbttn"
              /> */}
          </div>
        )}
        <br /> <br /> <br />
      </div>

      <div
        style={{
          position: policytype == "null" ?   "fixed" :'relative',
          width: "100%",
          bottom: "0",
          // marginTop: policytype == "null" ? "58vh" : "8vh",
        }}
      >
        <Footer className={"foterview"} />
      </div>
      <ToastContainer />
    </div>
  );
};

const UploadView = ({ selectedImage, removedata, onChange }) => (
  <div>
    {/* {selectedImage && (
      <div>
        <img
          alt="aadharcard"
          width={"50%"}
          height={"auto"}
          src={
            selectedImage?.type == "application/pdf"
              ? "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
              : URL.createObjectURL(selectedImage)
          }
        />
        <br />
        <button
          className="viewbttn33"
          style={{ backgroundColor: "red" }}
          onClick={removedata}
        >
          Remove
        </button>
        <br />
      </div>
    )} */}
 {selectedImage && (
      <div style={{ marginTop: 20, marginBottom: 0 }}>
       <div>
     
       <img
          alt="aadharcard"
          width={"150px"}
            height={"150px"}
          src={
            selectedImage?.type == "application/pdf"
              ? "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
              : URL.createObjectURL(selectedImage)
          }
        />
        <CancelIcon
          // className="viewbttn33"
          // style={{ backgroundColor: "red" }}
          onClick={removedata}
          className="actioncion11"
          // className="viewbttn33"
          style={{ color: "#c00" }}
        />
        </div>
     

        {/* <button
          className="viewbttn33"
          style={{ backgroundColor: "red" }}
          onClick={removedata}
        > */}

        {/* </button> */}
        <br/>
      </div>
    )}

    {/* <input
      type="file"
      name="myImage"
      accept="application/pdf,image/*"
      onChange={onChange}
    /> */}
      <input
      type="file"
      id="file"
      accept="application/pdf,image/*"
      onChange={onChange}
    />
    <label for="file" class="btn-2">
      upload
    </label>
  </div>
);
export default Addpolicy;
