import React, { useState } from "react";
import "./askexpert.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import Errortext from "../components/Errortext";
import { ClipLoader } from "react-spinners";
import { QuoteBuyAPI } from "../utils/ExportAPI";
import { SuccessToast } from "../utils/constant";
import { ToastContainer } from "react-toastify";
const Askexpert = () => {
  const [tabactive, setTabactive] = useState("health");
  const User = useSelector((state) => state.user);
  const [name, setName] = useState(User?.Profiledata.Name);
  const [email, setEmail] = useState(User?.Profiledata.Email);
  const [pnumber, setPnumber] = useState(User?.Profiledata.mobile);
  const [qmessageerroe, setQmessageerroe] = useState(false);
  const [messageQ, setMessageQ] = useState("");
  const [qload, setQload] = useState(false);
  function ExistQuoteAPI() {
    if (messageQ.length >= 10) {
      setQmessageerroe(false);
      setQload(true);
      QuoteBuyAPI(User?.userid, tabactive, name, email, pnumber, messageQ)
        .then((data) => {
          setQload(false);
          SuccessToast("Our team Contact you very soon");
          setMessageQ("");
        })
        .catch((e) => console.log(e));
    } else {
      setQmessageerroe(true);
    }
  }
  return (
    <div style={{ overflow: "hidden" }}>
      <Header backname={"Home"} pagename={"Policy"} to={"/homepage"} />

      {/* <h3 style={{ textAlign: "center" }}>Insurance Companies - Claim</h3> */}
      <div className="maidiv">
        <div>
          <button
            onClick={() => setTabactive("health")}
            className="clabttn"
            style={{
              backgroundColor: tabactive == "health" ? "#ffcb2b" : "#fff",
              border: "1px solid #ffcb2b",
              color: tabactive == "health" ? "#fff" : "#000",
            }}
          >
            Health
          </button>
          <p
            className="abustyle"
            style={{
              border:
                tabactive == "health" ? "8px solid #ffcb2b" : "8px solid #fff",

              display: tabactive == "health" ? "block" : "none",
            }}
          />
        </div>
        <div>
          <button
            onClick={() => setTabactive("motor")}
            className="clabttn"
            style={{
              backgroundColor: tabactive == "motor" ? "#ffcb2b" : "#fff",
              border: "1px solid #ffcb2b",
              color: tabactive == "motor" ? "#fff" : "#000",
            }}
          >
            Motor
          </button>
          <p
            className="abustyle"
            style={{
              border:
                tabactive == "motor" ? "8px solid #ffcb2b" : "8px solid #fff",

              display: tabactive == "motor" ? "block" : "none",
            }}
          />
        </div>
        <div>
          <button
            onClick={() => setTabactive("life")}
            className="clabttn"
            style={{
              backgroundColor: tabactive == "life" ? "#ffcb2b" : "#fff",
              border: "1px solid #ffcb2b",
              color: tabactive == "life" ? "#fff" : "#000",
            }}
          >
            Life
          </button>
          <p
            className="abustyle"
            style={{
              border:
                tabactive == "life" ? "8px solid #ffcb2b" : "8px solid #fff",

              display: tabactive == "life" ? "block" : "none",
            }}
          />
        </div>
        <div>
          <button
            onClick={() => setTabactive("other")}
            className="clabttn"
            style={{
              backgroundColor: tabactive == "other" ? "#ffcb2b" : "#fff",
              border: "1px solid #ffcb2b",
              color: tabactive == "other" ? "#fff" : "#000",
            }}
          >
            Other
          </button>
          <p
            className="abustyle"
            style={{
              border:
                tabactive == "other" ? "8px solid #ffcb2b" : "8px solid #fff",
              display: tabactive == "other" ? "block" : "none",
            }}
          />
        </div>
      </div>
      <div className="frmView" style={{ marginTop: 30 }}>
        <from>
          <h5 style={{ textAlign: "center" }}>
            {tabactive == "health"
              ? "Health Insurance"
              : tabactive == "motor"
              ? "Motor Insurance"
              : tabactive == "life"
              ? "Life Insurance"
              : "Other"}
          </h5>
          <br />
          {/* <div className="quoteflex"> */}
          <Fileditem
            labelname={"Full Name"}
            setvale={setName}
            value={name}
            placeholder={"Full Name"}
          />
          <Fileditem
            labelname={"EmailID"}
            setvale={setEmail}
            value={email}
            placeholder={"ex: myname@example.com"}
          />
          {/* </div> */}
          {/* <div className="quoteflex"> */}
          <Fileditem
            labelname={"Phone Number"}
            setvale={setPnumber}
            value={pnumber}
            placeholder={"Phone no."}
          />

          {/* </div> */}

          <text className="lablet">Additional Infromation</text>
          <textarea
            className="messfiled"
            rows={5}
            cols={5}
            value={messageQ}
            placeholder="Additional Infromation"
            onChange={(e) => setMessageQ(e.target.value)}
          />
          {qmessageerroe && (
            <Errortext
              marginTop={5}
              title={"Additional infromation is required!"}
            />
          )}
          <br />
          <br />
          <button onClick={() => ExistQuoteAPI()} className="viewbttn">
            {qload == false ? (
              "Submit"
            ) : (
              <ClipLoader color={"#fff"} loading={qload} size={12} />
            )}
          </button>
          <br />
        </from>
      </div>
      <div style={{marginTop:50}}/>
      {/* <div className="footview"> */}
      <Footer className={"foterview"} />
      {/* </div> */}
      <ToastContainer />
    </div>
  );
};

const Fileditem = ({ setvale, labelname, value, placeholder, type, style }) => (
  <div className="mainfilecc">
    <text className="lablet">{labelname + "*"}</text>
    <input
      required={true}
      style={style}
      type={type || "text"}
      className="homefiled"
      placeholder={placeholder || " Full Name"}
      value={value}
      onChange={(e) => setvale(e.target.value)}
    />
  </div>
);
export default Askexpert;
