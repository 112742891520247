import { toast } from "react-toastify";

export const SuccessToast = (message) => {
  toast.success(message);
};
export const ErrorToast = (message) => {
  toast.error(message);
};

export const InfoToast = (message) => {
  toast.info(message);
};

export const policydatas = [
  {
    name : "Jhon",
    product : "Health Insurance",
    PolicyName : "Anthem",
    PlanRegistration : "Star Health",
    PolicyNo : 'PP1A968TRI843',
    Company : "HDFC Ergo",
    Expiry : "02-05-2023",
    get:true,
  },
  {
    name : "Prince",
    product : "Life Insurance",
    PolicyName : "LIC’s Jeevan Pragati",
    PlanRegistration : "Life Cover",
    PolicyNo : 'PP1A968TRI843',
    Company : "LIC",
    Expiry : "01-12-2025",
    get:false,
  },
  {
    name : "Yesh",
    product : "Health Insurance",
    PolicyName : "Anthem",
    PlanRegistration : "Life Cover",
    PolicyNo : 'PP1A968TRI843',
    Company : "HDFC Ergo",
    Expiry : "01-12-2028",
    get:false,
  },
  {
    name : "Durgesh",
    product : "Motor Insurance",
    PolicyName : "SBI General",
    PlanRegistration : "MAC221A968TRI",
    PolicyNo : 'PP1A968TRI843',
    Company : "SBI General Insurance",
    Expiry : "01-12-2027",
    get:false,
  },
  {
    name : "Mahi",
    product : "Life Insurance",
    PolicyName : "Endowment Plan",
    PlanRegistration : "Life Cover",
    PolicyNo : 'PP1A968TRI843',
    Company : "LIC",
    Expiry : "01-12-2031",
    get:false,
  },
  {
    name : "Kunal",
    product : "Health Insurance",
    PolicyName : "Anthem",
    PlanRegistration : "Liberty General Insurance",
    PolicyNo : 'PP1A968TRI843',
    Company : "HDFC Ergo",
    Expiry : "01-12-2028",
    get:false,
  },
]