import React, { useState } from "react";
import CustomeButton from "../components/CustomeButton";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./Profile.css";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import {
  Update_mobile,
  Update_mobile1,
  Update_password,
  Update_profile,
} from "../utils/ExportAPI";
import { ErrorToast, InfoToast, SuccessToast } from "../utils/constant";
import { ClipLoader } from "react-spinners";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Inputfiled from "../components/Inputfiled";
import { ToastContainer } from "react-toastify";
import Errortext from "../components/Errortext";
import OtpInput from "react-otp-input";
import { Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import BorderColorIcon from "@mui/icons-material/BorderColor";
const Profile = () => {
  const User = useSelector((state) => state.user);
  const passwordRegex = /^(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const [edit, setEdit] = useState(false);
  const [emailedit, setEmailedit] = useState(false);
  const [mobileedit, setMobileedit] = useState(false);
  const [mobileedit1, setMobileedit1] = useState(false);
  const [mobileedit2, setMobileedit2] = useState(false);
  const [passedit, setPassedit] = useState(false);
  const [name, setName] = useState(User?.Profiledata.Name);
  const [email, setEmail] = useState(User?.Profiledata.Email);
  const [load1, setLoad1] = useState(false);
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [confpass, setConfpass] = useState("");
  const [newshow, setNewshow] = useState(false);
  const [oldshow, setOldshow] = useState(false);
  const [confshow, setConfshow] = useState(false);
  const [olderroe, setOlderroe] = useState(false);
  const [newerroer, setNewerroer] = useState(false);
  const [conferroe, setConferroe] = useState(false);
  const [passload, setPassload] = useState(false);
  const [mobile, setMobile] = useState([]);
  const [merre, setMerre] = useState(false);
  const [merre1, setMerre1] = useState(false);
  const [merre2, setMerre2] = useState(false);
  const [mobile2, setMobile2] = useState(User?.Profiledata.mobile);
  const [mid, setMid] = useState("");
  const [otp, setOtp] = useState("");
  const [mid1, setMid1] = useState("");
  const [otp1, setOtp1] = useState("");
  const [mid2, setMid2] = useState("");
  const [otp2, setOtp2] = useState("");
  const [moloader, setMoloader] = useState(false);
  const [moloader1, setMoloader1] = useState(false);
  const [moloader2, setMoloader2] = useState(false);
  const [showmobileotp, setShowmobileotp] = useState(false);
  const [otpload, setOtpload] = useState(false);
  const [otperroe, setOtperroe] = useState(false);
  const [showmobileotp1, setShowmobileotp1] = useState(false);
  const [otpload1, setOtpload1] = useState(false);
  const [otperroe1, setOtperroe1] = useState(false);
  const [showmobileotp2, setShowmobileotp2] = useState(false);
  const [otpload2, setOtpload2] = useState(false);
  const [otperroe2, setOtperroe2] = useState(false);
  const handleAddInputField = () => {
    setMobile([...mobile, ""]);
  };
  function RemoveItem(index) {
    const newArray = [...mobile];
    newArray.splice(index, 1);
    setMobile(newArray);
  }

  const MobileValidation1 = (text) => {
    const numericValue = text.replace(/[^0-9]/g, "");
    setMobile2(numericValue);
  };

  function updateProfile() {
    setLoad1(true);
    Update_profile(User?.userid, name, email)
      .then((data) => {
        setLoad1(false);
        setEdit(false);
        setEmailedit(false);
        SuccessToast(data.message);
      })
      .catch((e) => console.log(e));
  }

  function Validation() {
    if (passwordRegex.test(oldpass)) {
      setOlderroe(false);
      if (passwordRegex.test(newpass)) {
        setNewerroer(false);
        if (newpass == confpass) {
          setConferroe(false);

          updatePassword();
        } else {
          setConferroe(true);
        }
      } else {
        setNewerroer(true);
      }
    } else {
      setOlderroe(true);
    }
  }

  function updatePassword() {
    setPassload(true);
    Update_password(User?.userid, oldpass, newpass)
      .then((data) => {
        setPassedit(false);
        setPassload(false);
        setEdit(false);
        setOldpass("");
        setNewpass("");
        setConfpass("");
        setOldshow(false);
        setNewshow(false);
        setConfshow(false);
        SuccessToast(data.message);
      })
      .catch((e) => console.log(e));
  }
  async function GetOtp_API() {
    if (!mobile2) {
      InfoToast("Pls Enter Valid Mobile");
      return;
    }
    try {
      setOtpload(true);
      const body = new FormData();
      body.append("mobile", mobile2);
      body.append("contact", mobile2);
      body.append("type", "mobileupdate");
      const res = await fetch(
        "https://insugo.vensframe.com/api/otp-request.php",
        {
          method: "POST",
          body: body,
        }
      );
      const rslt = await res.json();
      // console.log("get opt=====>", rslt);
      if (rslt.success === true) {
        setMid(rslt?.id);
        setOtpload(false);
        SuccessToast(rslt.message);
        setShowmobileotp(true);
      } else {
        setOtpload(false);
        ErrorToast(rslt.message);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function GetOtp_API1() {
    if (!mobile?.[0]) {
      InfoToast("Pls Enter Valid Mobile");
      return;
    }
    try {
      setOtpload1(true);
      const body = new FormData();
      body.append("mobile", mobile?.[0]);
      body.append("contact", mobile?.[0]);
      body.append("type", "mobileupdate");
      const res = await fetch(
        "https://insugo.vensframe.com/api/otp-request.php",
        {
          method: "POST",
          body: body,
        }
      );
      const rslt = await res.json();
      // console.log("get opt=====>", rslt);
      if (rslt.success === true) {
        setMid1(rslt?.id);
        setOtpload1(false);
        SuccessToast(rslt.message);
        setShowmobileotp1(true);
      } else {
        setOtpload1(false);
        ErrorToast(rslt.message);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function GetOtp_API2() {
    console.log("mobile?.[1]", mobile?.[1]);
    if (!mobile?.[1]) {
      InfoToast("Pls Enter Valid Mobile");
      return;
    }
    try {
      setOtpload2(true);
      const body = new FormData();
      body.append("mobile", mobile?.[1]);
      body.append("contact", mobile?.[1]);
      body.append("type", "mobileupdate");
      const res = await fetch(
        "https://insugo.vensframe.com/api/otp-request.php",
        {
          method: "POST",
          body: body,
        }
      );
      const rslt = await res.json();
      // console.log("get opt=====>", rslt);
      if (rslt.success === true) {
        setMid2(rslt?.id);
        setOtpload2(false);
        SuccessToast(rslt.message);
        setShowmobileotp2(true);
      } else {
        setOtpload2(false);
        ErrorToast(rslt.message);
      }
    } catch (e) {
      console.log(e);
    }
  }
  function updatemobile() {
    if (mobile2?.length == 10) {
      setMerre(false);
      if (otp?.length == 6) {
        setOtperroe(false);
        setMoloader(true);
        Update_mobile(User?.userid, mobile2, mid, otp)
          .then((data) => {
            setMobileedit(false);
            setMoloader(false);
            setShowmobileotp(false);
            setOtp("");
            setMobile2("");
            setMobile([]);
            SuccessToast(data.message);
          })
          .catch((e) => console.log(e));
      } else {
        setOtperroe(true);
      }
    } else {
      setMerre(true);
    }
  }
  function updatemobile1() {
    if (mobile?.[0]?.length == 10) {
      setMerre1(false);
      if (otp1?.length == 6) {
        setOtperroe1(false);
        setMoloader1(true);
        Update_mobile1(User?.userid, mobile?.[0], mid1, otp1)
          .then((data) => {
            setMobileedit1(false);
            setMoloader1(false);
            setShowmobileotp1(false);
            setOtp1("");
            setMobile([]);
            SuccessToast(data.message);
          })
          .catch((e) => console.log(e));
      } else {
        setOtperroe1(true);
      }
    } else {
      setMerre1(true);
    }
  }
  function updatemobile2() {
    if (mobile?.[1]?.length == 10) {
      setMerre2(false);
      if (otp2?.length == 6) {
        setOtperroe2(false);
        setMoloader2(true);
        Update_mobile(User?.userid, mobile?.[1], mid2, otp2)
          .then((data) => {
            setMobileedit2(false);
            setMoloader2(false);
            setShowmobileotp2(false);
            setOtp2("");
            setMobile([]);
            SuccessToast(data.message);
          })
          .catch((e) => console.log(e));
      } else {
        setOtperroe2(true);
      }
    } else {
      setMerre2(true);
    }
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Header backname={"Home"} pagename={"Profile"} to={"/homepage"} />
      <Row>
        <Col xs={12} md={6}>
          <div className="frdiv">
            <div className="tofflex">
              <h3 style={{ textAlign: "center" }}>Profile</h3>
              {/* {edit == false && (
                <button onClick={() => setEdit(true)} className="edibttn">
                  Edit
                </button>
              )} */}
            </div>
            {/* <div>
              <text className="lablet">Name</text>
              <input
                disabled={edit ? false : true}
                style={{ backgroundColor: edit ? "#fff" : "#efefef" }}
                type={"text"}
                className="inputset"
                placeholder=" User Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div> */}
            <Fileditem
              labelname={"Name"}
              Iconpress={() => setEdit(true)}
              edit={edit}
              type={"text"}
              value={name}
              placeholder="User Name"
              setvalue={setName}
              Icon={edit && <div />}
            />
            {edit && (
              // <button onClick={() => } className="viewbttn">
              //   Update
              // </button>
              <div style={{ display: "inline-flex", marginBottom: 20 }}>
                <button
                  type="submit"
                  onClick={() => updateProfile()}
                  className="viewbttn"
                >
                  {load1 == false ? (
                    "Update"
                  ) : (
                    <ClipLoader color={"#fff"} loading={load1} size={12} />
                  )}
                </button>

                <button
                  style={{ marginLeft: 20, backgroundColor: "#727b82" }}
                  type="submit"
                  onClick={() => setEdit(false)}
                  className="viewbttn"
                >
                  Cancel
                </button>
              </div>
            )}
            <Fileditem
              labelname={"Email ID"}
              Iconpress={() => setEmailedit(true)}
              edit={emailedit}
              type={"email"}
              value={email}
              placeholder="Email ID"
              setvalue={setEmail}
              Icon={emailedit && <div />}
            />

            {/* <div>
              <text className="lablet">Email ID</text>
              <input
                disabled={edit ? false : true}
                style={{ backgroundColor: edit ? "#fff" : "#efefef" }}
                type={"email"}
                className="inputset"
                placeholder="Email ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div> */}

            {emailedit && (
              // <button onClick={() => } className="viewbttn">
              //   Update
              // </button>
              <div style={{ display: "inline-flex", marginBottom: 20 }}>
                <button
                  type="submit"
                  onClick={() => updateProfile()}
                  className="viewbttn"
                >
                  {load1 == false ? (
                    "Update"
                  ) : (
                    <ClipLoader color={"#fff"} loading={load1} size={12} />
                  )}
                </button>

                <button
                  style={{ marginLeft: 20, backgroundColor: "#727b82" }}
                  type="submit"
                  onClick={() => setEmailedit(false)}
                  className="viewbttn"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          <div style={{ marginTop: -20 }} className="frdiv">
            <div className="tofflex">
              {passedit == false && (
                <button
                  style={{ margin: 0 }}
                  onClick={() => setPassedit(true)}
                  className="viewbttn"
                >
                  Change Password
                </button>
              )}
              {/* // <h3 style={{ textAlign: "left" }}>Change Password</h3> */}
            </div>
            {passedit && (
              <div>
                <text className="lablet">Old Password</text>
                <div style={{ display: "flex" }}>
                  <input
                    style={{ backgroundColor: passedit ? "#fff" : "#efefef" }}
                    type={oldshow ? "text" : "password"}
                    placeholder="Old Password"
                    value={oldpass}
                    onChange={(e) => setOldpass(e.target.value)}
                    disabled={passedit ? false : true}
                    className="inputset"
                  />

                  {oldshow ? (
                    <VisibilityOffIcon
                      onClick={() => {
                        setOldshow(false);
                      }}
                      className="icoshow23"
                    />
                  ) : (
                    <VisibilityIcon
                      style={{ backgroundColor: passedit ? "#fff" : "#efefef" }}
                      onClick={() => {
                        {
                          passedit == false
                            ? setOldshow(false)
                            : setOldshow(true);
                        }
                      }}
                      className="icoshow23"
                    />
                  )}
                </div>
                {olderroe && (
                  <Errortext
                    marginTop={5}
                    title={
                      "Password must be at least 8 characters long and contain at least  one special character !"
                    }
                  />
                )}
                <text className="lablet">New Password</text>
                <div style={{ display: "flex" }}>
                  <input
                    style={{ backgroundColor: passedit ? "#fff" : "#efefef" }}
                    type={newshow ? "text" : "password"}
                    placeholder="New Password"
                    value={newpass}
                    onChange={(e) => setNewpass(e.target.value)}
                    disabled={passedit ? false : true}
                    className="inputset"
                  />

                  {newshow ? (
                    <VisibilityOffIcon
                      onClick={() => {
                        setNewshow(false);
                      }}
                      className="icoshow23"
                    />
                  ) : (
                    <VisibilityIcon
                      style={{ backgroundColor: passedit ? "#fff" : "#efefef" }}
                      onClick={() => {
                        {
                          passedit == false
                            ? setNewshow(false)
                            : setNewshow(true);
                        }
                      }}
                      className="icoshow23"
                    />
                  )}
                </div>
                {newerroer && (
                  <Errortext
                    marginTop={5}
                    title={
                      "Password must be at least 8 characters long and contain at least  one special character !"
                    }
                  />
                )}
                <text className="lablet">Confrim Password</text>
                <div style={{ display: "flex" }}>
                  <input
                    style={{ backgroundColor: passedit ? "#fff" : "#efefef" }}
                    type={confshow ? "text" : "password"}
                    className="inputset"
                    placeholder="Confrim Password"
                    value={confpass}
                    onChange={(e) => setConfpass(e.target.value)}
                    disabled={passedit ? false : true}
                  />

                  {confshow ? (
                    <VisibilityOffIcon
                      onClick={() => {
                        setConfshow(false);
                      }}
                      className="icoshow23"
                    />
                  ) : (
                    <VisibilityIcon
                      style={{ backgroundColor: passedit ? "#fff" : "#efefef" }}
                      onClick={() => {
                        {
                          passedit == false
                            ? setConfshow(false)
                            : setConfshow(true);
                        }
                      }}
                      className="icoshow23"
                    />
                  )}
                </div>
                {conferroe && (
                  <Errortext
                    marginTop={5}
                    title={"password and confirm password not matched!"}
                  />
                )}
                <br />
                {passedit && (
                  <div style={{ display: "inline-flex", marginBottom: 20 }}>
                    <button onClick={() => Validation()} className="viewbttn">
                      {passload == false ? (
                        "Update"
                      ) : (
                        <ClipLoader
                          color={"#fff"}
                          loading={passload}
                          size={12}
                        />
                      )}
                    </button>

                    <button
                      style={{ marginLeft: 20, backgroundColor: "#727b82" }}
                      type="submit"
                      onClick={() => {
                        setPassedit(false);
                        setConferroe(false);
                        setNewerroer(false);
                        setOlderroe(false);
                      }}
                      className="viewbttn"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>

        <Col xs={12} md={6}>
          <div className="frdiv">
            <div className="tofflex">
              <h3 style={{ textAlign: "center" }}>Mobile</h3>
              {/* {mobileedit == false && (
                // <button onClick={() => setMobileedit(true)} className="edibttn">
                //   Edit
                // </button>
                <BorderColorIcon onClick={() => setMobileedit(true)} />
              )} */}
            </div>

            <div>
              <div className="icoflow">
                <text className="lablet">Mobile No.</text>
              </div>
              <div style={{ display: "flex" }}>
                <input
                  disabled={mobileedit ? false : true}
                  style={{ backgroundColor: mobileedit ? "#fff" : "#efefef" }}
                  type={"number"}
                  className="inputset"
                  placeholder="Mobile no."
                  value={mobile2}
                  onChange={(e) => setMobile2(e.target.value)}
                />

                {mobileedit == false ? (
                  <EditIcon
                    style={{ marginLeft: 10, marginTop: 5 }}
                    onClick={() => setMobileedit(true)}
                  />
                ) : mobile2 != User?.Profiledata.mobile ? (
                  <button
                    style={{
                      backgroundColor:
                        mobile2 == User?.Profiledata.mobile
                          ? "#008000"
                          : "#00800080",
                    }}
                    disabled={
                      mobile2 == User?.Profiledata.mobile ? true : false
                    }
                    onClick={() => GetOtp_API()}
                    className="otpbttn"
                  >
                    {otpload == false ? (
                      showmobileotp == true ? (
                        "Resend"
                      ) : (
                        "Get OTP"
                      )
                    ) : (
                      <ClipLoader color={"#fff"} loading={otpload} size={12} />
                    )}
                  </button>
                ) : (
                  <text></text>
                )}
              </div>
              {mobileedit && mobile2 == User?.Profiledata.mobile && (
                <text style={{ display: "block", marginBottom: "5px" }}>
                  Change mobile number for update{" "}
                </text>
              )}

              {merre && (
                <Errortext marginTop={5} title={"Invalid mobile number"} />
              )}

              {showmobileotp && (
                <div style={{ marginBottom: 20 }}>
                  <div className="otpdicer">
                    <OtpInput
                      inputType="tel"
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      shouldAutoFocus
                      inputStyle={{
                        width: 28,
                        height: 28,
                        textAlign: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        margin: "auto",
                        borderRadius: 5,
                      }}
                      renderSeparator={<span className="spaerate"></span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </div>
              )}

              {otperroe && (
                <Errortext marginTop={5} title={"OTP number is required!"} />
              )}
            </div>
            {mobileedit && (
              <div style={{ display: "inline-flex", marginBottom: 20 }}>
                <button onClick={() => updatemobile()} className="viewbttn">
                  {moloader == false ? (
                    "Update"
                  ) : (
                    <ClipLoader color={"#fff"} loading={moloader} size={12} />
                  )}
                </button>

                <button
                  style={{ marginLeft: 20, backgroundColor: "#727b82" }}
                  type="submit"
                  onClick={() => {
                    setMobileedit(false);
                    setOtperroe(false);
                    setMerre(false);
                    setShowmobileotp(false);
                  }}
                  className="viewbttn"
                >
                  Cancel
                </button>
              </div>
            )}
            <br />
            {/* {mobileedit && ( */}
            <button
              onClick={() => (mobile?.length == 2 ? "" : handleAddInputField())}
              className="edibttn11"
            >
              Add Mobile
            </button>
            {/* )} */}

            <div>
              <br />
              {mobile.map((value, index) =>
                index == 0 ? (
                  <div>
                    <div className="addapaned">
                      <input
                        type={"number"}
                        className="inputset"
                        placeholder={"Mobile no 1"}
                        // disabled={mobileedit1 ? false : true}
                        // style={{
                        //   backgroundColor: mobileedit1 ? "#fff" : "#efefef",
                        // }}
                        key={index}
                        value={value}
                        onChange={(e) => {
                          const newInputFields = [...mobile];
                          newInputFields[index] = e.target.value;
                          setMobile(newInputFields);
                        }}
                      />
                      <HighlightOffIcon
                        className="remoicooo"
                        onClick={() => RemoveItem(index)}
                      />
                      {mobileedit1 == false ? (
                        <EditIcon
                          style={{ marginLeft: 10, marginTop: 5 }}
                          onClick={() => setMobileedit1(true)}
                        />
                      ) : (
                        <button
                          style={{
                            backgroundColor:
                              mobile?.[0]?.length >= 7
                                ? "#008000"
                                : "#00800080",
                          }}
                          disabled={mobile?.[0]?.length >= 7 ? false : true}
                          onClick={() => GetOtp_API1()}
                          className="otpbttn"
                        >
                          {otpload1 == false ? (
                            showmobileotp1 == true ? (
                              "Resend"
                            ) : (
                              "Get OTP"
                            )
                          ) : (
                            //   verified
                            // Not verified
                            <ClipLoader
                              color={"#fff"}
                              loading={otpload1}
                              size={12}
                            />
                          )}
                        </button>
                      )}
                    </div>
                    {merre1 && (
                      <Errortext
                        marginTop={5}
                        title={"Invalid mghggobile number"}
                      />
                    )}

                    {showmobileotp1 && (
                      <div style={{ marginBottom: 20 }}>
                        <div className="otpdicer">
                          <OtpInput
                            inputType="tel"
                            value={otp1}
                            onChange={setOtp1}
                            numInputs={6}
                            shouldAutoFocus
                            inputStyle={{
                              width: 28,
                              height: 28,
                              textAlign: "center",
                              backgroundColor: "#fff",
                              border: "1px solid #ccc",
                              margin: "auto",
                              borderRadius: 5,
                            }}
                            renderSeparator={<span className="spaerate"></span>}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      </div>
                    )}

                    {otperroe1 && (
                      <Errortext
                        marginTop={5}
                        title={"OTP number is required!"}
                      />
                    )}

                    {mobileedit1 && (
                      <div style={{ display: "inline-flex", marginBottom: 20 }}>
                        <button
                          onClick={() => updatemobile1()}
                          className="viewbttn"
                        >
                          {moloader1 == false ? (
                            "Update"
                          ) : (
                            <ClipLoader
                              color={"#fff"}
                              loading={moloader1}
                              size={12}
                            />
                          )}
                        </button>

                        <button
                          style={{ marginLeft: 20, backgroundColor: "#727b82" }}
                          type="submit"
                          onClick={() => {
                            setMobileedit1(false);
                            setOtperroe1(false);
                            setMerre1(false);
                            setShowmobileotp1(false);
                          }}
                          className="viewbttn"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                    <br />
                    <br />
                  </div>
                ) : (
                  mobile?.[0].length == 10 &&
                  index == 1 && (
                    <div>
                      <div className="addapaned">
                        <input
                          type={"number"}
                          className="inputset"
                          placeholder={"Mobile no 2"}
                          // disabled={mobileedit2 ? false : true}
                          style={
                            {
                              // backgroundColor: mobileedit2 ? "#fff" : "#efefef",
                            }
                          }
                          key={index}
                          value={value}
                          onChange={(e) => {
                            const newInputFields = [...mobile];
                            newInputFields[index] = e.target.value;
                            setMobile(newInputFields);
                          }}
                        />
                        <HighlightOffIcon
                          className="remoicooo"
                          onClick={() => RemoveItem(index)}
                        />
                        {mobileedit2 == false ? (
                          <EditIcon
                            style={{ marginLeft: 10, marginTop: 5 }}
                            onClick={() => setMobileedit2(true)}
                          />
                        ) : (
                          <button
                            style={{
                              backgroundColor:
                                mobile?.[1]?.length >= 7
                                  ? "#008000"
                                  : "#00800080",
                            }}
                            disabled={mobile?.[1]?.length >= 7 ? false : true}
                            onClick={() => GetOtp_API2()}
                            className="otpbttn"
                          >
                            {otpload2 == false ? (
                              showmobileotp2 == true ? (
                                "Resend"
                              ) : (
                                "Get OTP"
                              )
                            ) : (
                              //   verified
                              // Not verified
                              <ClipLoader
                                color={"#fff"}
                                loading={otpload2}
                                size={12}
                              />
                            )}
                          </button>
                        )}
                      </div>
                      {merre2 && (
                        <Errortext
                          marginTop={5}
                          title={"Invalid mobile number"}
                        />
                      )}

                      {showmobileotp2 && (
                        <div style={{ marginBottom: 20 }}>
                          <div className="otpdicer">
                            <OtpInput
                              inputType="number"
                              value={otp2}
                              onChange={setOtp2}
                              numInputs={6}
                              shouldAutoFocus
                              inputStyle={{
                                width: 28,
                                height: 28,
                                textAlign: "center",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                margin: "auto",
                                borderRadius: 5,
                              }}
                              renderSeparator={
                                <span className="spaerate"></span>
                              }
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                        </div>
                      )}

                      {otperroe2 && (
                        <Errortext
                          marginTop={5}
                          title={"OTP number is required!"}
                        />
                      )}

                      {mobileedit2 && (
                        <div
                          style={{ display: "inline-flex", marginBottom: 20 }}
                        >
                          <button
                            onClick={() => updatemobile2()}
                            className="viewbttn"
                          >
                            {moloader2 == false ? (
                              "Update"
                            ) : (
                              <ClipLoader
                                color={"#fff"}
                                loading={moloader2}
                                size={12}
                              />
                            )}
                          </button>

                          <button
                            style={{
                              marginLeft: 20,
                              backgroundColor: "#727b82",
                            }}
                            type="submit"
                            onClick={() => {
                              setMobileedit2(false);
                              setOtperroe2(false);
                              setMerre2(false);
                              setShowmobileotp2(false);
                            }}
                            className="viewbttn"
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                      <br />
                      <br />
                    </div>
                  )
                )
              )}
            </div>

            <br />
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col xs={12} md={6}>
         
        </Col>
      </Row> */}
      <div style={{ marginTop: "21.5%" }} />
      <Footer className={"foterview"} />
      <ToastContainer />
    </div>
  );
};

const Fileditem = ({
  value,
  placeholder,
  type,
  setvalue,
  Icon,
  edit,
  Iconpress,
  labelname,
  Icon2,
}) => (
  <div className="">
    <text className="lablet">{labelname}</text>
    <div style={{ display: "addapaned" }}>
      <input
        disabled={edit ? false : true}
        style={{ backgroundColor: edit ? "#fff" : "#efefef" }}
        type={type || "tel"}
        className="inputset"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setvalue(e.target.value)}
      />
      {Icon || <EditIcon className="EditoIICon" onClick={Iconpress} />}
    </div>
  </div>
);
export default Profile;
