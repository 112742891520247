import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sendotp from "./screens/Sendotp";
import Profile from "./screens/Profile";
import Singup from "./screens/Singup";
import Addpolicy from "./screens/Addpolicy";
import Adddocument from "./screens/Adddocument ";
import Claims from "./screens/Claims";
import Askexpert from "./screens/Askexpert";
import Notificationlist from "./screens/Notificationlist";
import Notificationdetail from "./screens/Notificationdetail";
import Updatepolicy from "./screens/Updatepolicy";
import Updatedocument from "./screens/Updatedocument";
import { useEffect, useState } from "react";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import Home from "./screens/Homepage";
import Homepage from "./screens/Homepage";

// import { Push_TokenAPI } from "./utils/ExportAPI";
function App() {
  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission == "granted") {
  //     //generate tokan
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BASEmvZNPVBfJPH4doO-Xiy6B1S7TuuftRRDO0xxJDMPkyNhmohS_NCCxOT8POXkLDyTS49xH26Vo7PM_9as1S4",
  //     });
  //     // Push_TokenAPI(token)
  //     console.log("token", token);
  //   } else if (permission == "denied") {
  //     alert("permission denid");
  //   }
  // }

  useEffect(() => {
    // requestPermission();
  }, []);
  // const totak = 'fBmRRYmzHkCH0EOy4VHMWV:APA91bGpN7UQatkv-ZP1_RLzVaB97H3HXxLrUsvWeZBecjinJ5VDoT10owucSwimdr1ocqEUvDdDmqDYxTH33_D_NaU5sn9CrCc2f4kaWr05VUZ-a8adgdImgSdEoPImpzTDE1-0x1sl'
  return (
    <Router>
      <Routes >
        <Route exact path="/" element={<Sendotp />} />
        <Route exact path="/homepage" element={<Homepage />} />
        <Route exact path="/singup" element={<Singup />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/addpolicy" element={<Addpolicy />} />
        <Route exact path="/adddocument" element={<Adddocument />} />
        <Route exact path="/claims" element={<Claims />} />
        <Route exact path="/askexpert" element={<Askexpert />} />
        <Route exact path="/notificationlist" element={<Notificationlist />} />
        <Route
          exact
          path="/notificationdetail"
          element={<Notificationdetail />}
        />
        <Route exact path="/Updatepolicy" element={<Updatepolicy />} />
        <Route exact path="/Updatedocument" element={<Updatedocument />} />
      </Routes>
    </Router>
  );
}

export default App;
