import React, { useEffect, useState } from "react";
import "./Singup.css";
import logoimg from "../assets/icon/Logo.png";
import CustomeButton from "../components/CustomeButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorToast, InfoToast, SuccessToast } from "../utils/constant";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Inputfiled from "../components/Inputfiled";
import useGeolocation from "react-hook-geolocation";
import logkay from "../assets/images/bbinsugo.png";
import Errortext from "../components/Errortext";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { USER } from "../redux/actions/ActionType";
const Singup = () => {
  const navigate = useNavigate();
  const geolocation = useGeolocation();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const User = useSelector((state) => state.user);
  const [otp, setOtp] = useState("");
  const [locarray, setLocarray] = useState("");
  // console.log('geolocation',locarray.filter((v)=>v.latitude || v.longitude));
  const [emailotp, setEmailotp] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confpass, setConfpass] = useState("");
  const [email, setEmail] = useState("");
  const [otpview, setOtpview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [veriload, setVeriload] = useState(false);
  const [showpass, setShowpass] = useState(false);
  const [showconf, setShowconf] = useState(false);
  const [showmobileotp, setShowmobileotp] = useState(false);
  const [otpID, setOtpID] = useState("");
  const Regemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const passwordRegex = /^(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const [passerroe, setPasserroe] = useState(false);
  const [mobileerroe, setMobileerroe] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [emailerroe, setEmailerroe] = useState(false);
  const [nameerroe, setNameerroe] = useState(false);
  const [confpasserrore, setConfpasserrore] = useState(false);
  const [otperroe, setOtperroe] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleChange = (event) => {
    setMobile(event.target.value);
  };
  async function GetOtp_API() {
    if (!mobile) {
      InfoToast("Pls Enter Valid Mobile");
      return;
    }
    try {
      setLoader(true);
      const body = new FormData();
      body.append("contact", mobile);
      body.append("type", "signup");
      const res = await fetch(
        "https://insugo.vensframe.com/api/otp-request.php",
        {
          method: "POST",
          body: body,
        }
      );
      const rslt = await res.json();
      // console.log("get opt=====>", rslt);
      if (rslt.success === true) {
        setOtpID(rslt?.id);
        setLoader(false);
        SuccessToast(rslt.message);
        setOtpview(true);
        setShowmobileotp(true);
      } else {
        setLoader(false);
        ErrorToast(rslt.message);
      }
    } catch (e) {
      console.log(e);
    }
  }
  function Validation() {
    if (name?.length >= 1) {
      setNameerroe(false);
      if (mobile?.length >= 7) {
        setMobileerroe(false);
        if (email?.length >= 1 && Regemail.test(email)) {
          setEmailerroe(false);
          if (passwordRegex.test(password)) {
            setPasserroe(false);
            if (password == confpass) {
              setConfpasserrore(false);
              if (otp?.length == 6) {
                setOtperroe(false);
                SignupAPI();
              } else {
                setOtperroe(true);
              }
            } else {
              setConfpasserrore(true);
            }
          } else {
            setPasserroe(true);
          }
        } else {
          setEmailerroe(true);
        }
      } else {
        setMobileerroe(true);
      }
    } else {
      setNameerroe(true);
    }
  }
  async function SignupAPI() {
    try {
      setVeriload(true);
      const url = "https://insugo.vensframe.com/api/signup.php";
      const body = new FormData();
      body.append("name", name);
      body.append("email", email);
      body.append("contact", mobile);
      body.append("id", otpID);
      body.append("otp", otp);
      body.append(
        "latlong",
        geolocation?.latitude + "," + geolocation?.longitude
      );
      body.append("password", password);
      body.append("platform", "Web");
      body.append("type", "Web");
      const res = await fetch(url, {
        method: "Post",
        body: body,
      });
      const rslt = await res.json();
      console.log('rslt',rslt);
      if (rslt.success === true) {
        setVeriload(false);
        SuccessToast(rslt.message);
        dispatch({
          type: USER,
          payload: {
            userid: rslt.userid,
            login: true,
          },
        });
        cookies.set("UserID", rslt.userid);
        navigate("/homepage", { replace: true });
      } else {
        setVeriload(false);
        ErrorToast(rslt.message);
      }
    } catch (e) {
      console.log(e);
    }
  }
  
  return (
    <div className="container-fluid">
      <div className="leftycont">
        <img src={logkay} className="logyfyy" />
        <h2
          style={{
            fontSize: 50,
            marginBottom: 15,
            marginTop: 0,
            fontFamily: "inherit",
          }}
        >
          Covering your RISKS
        </h2>
        <text>to retain the worthiness of your Life and possessions.</text>
      </div>

      <div className="SignUpBox" style={{ height: "auto" }}>
        <div className="formdiv">
          <img src={logoimg} alt="insugoimg" className="imagemobi" />
          <h4 className="text1"> Signup</h4>
          <Inputfiled
            type={"text"}
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameerroe && <Errortext marginTop={5} title={"Name is required!"} />}

          <div style={{ display: "flex" }}>
            <Inputfiled
              type={"number"}
              placeholder="Mobile no."
              value={mobile}
              onChange={handleChange}
            />

            <button
              style={{
                backgroundColor: mobile?.length >= 7 ? "#008000" : "#00800080",
              }}
              disabled={mobile?.length >= 7 ? false : true}
              onClick={() => GetOtp_API()}
              className="otpbttn"
            >
              {loader == false ? (
                showmobileotp == true ? (
                  "Resend"
                ) : (
                  "Get OTP"
                )
              ) : (
                //   verified
                // Not verified
                <ClipLoader color={"#fff"} loading={loading} size={12} />
              )}
            </button>
          </div>
          {mobileerroe && (
            <Errortext marginTop={5} title={"Mobile number is required!"} />
          )}
          {otperroe && (
            <Errortext marginTop={5} title={"OTP number is required!"} />
          )}
          {showmobileotp && (
            <div style={{ marginBottom: 20 }}>
              <div className="otpdicer">
                <OtpInput
                  inputType="tel"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  shouldAutoFocus
                  inputStyle={{
                    width: 28,
                    height: 28,
                    textAlign: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    margin: "auto",
                    borderRadius: 5,
                  }}
                  renderSeparator={<span className="spaerate"></span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              {/* <div
                style={{
                  textAlign: "center",
                  marginTop: 20,
                  marginRight: "20px",
                }}
              >
                <CustomeButton
                  //   disabled={veriload}
                  //   onClickHandler={() => verifyOtp_API()}
                  onClickHandler={() => setShowmobileotp(false)}
                  buttonname={
                    veriload == false ? (
                      "Verify Number"
                    ) : (
                      <ClipLoader color={"#fff"} loading={true} size={12} />
                    )
                  }
                />
              </div> */}
            </div>
          )}
          {/* { */}
          {/* <div style={{ display: "flex" }}> */}
          <Inputfiled
            type={"email"}
            placeholder="Email ID"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailerroe && (
            <Errortext marginTop={5} title={"Email is required!"} />
          )}
          {/* <div
                style={{
                  textAlign: "center",
                  marginTop: 4,
                  marginLeft: 6,
                  marginRight: -17,
                }}
              >
                <CustomeButton
                  fontSize={13}
                  buttoncolor={"green"}
                  textTcolor={"#fff"}
                  disabled={email?.length >= 1 ? false : true}
                  //   onClickHandler={() => GetOtp_API()}
                  onClickHandler={() => setShowemailotp(true)}
                  buttonname={
                    loading == false ? (
                      "verify"
                    ) : (
                      //   verified
                      // Not verified
                      <ClipLoader color={"#fff"} loading={loading} size={12} />
                    )
                  }
                />
              </div> */}
          {/* </div> */}
          {/* } */}
          {/* {showemailotp && (
            <div style={{ marginBottom: 20 }}>
              <div className="otpdicer">
                <OtpInput
                  inputType="tel"
                  value={emailotp}
                  onChange={setEmailotp}
                  numInputs={6}
                  shouldAutoFocus
                  inputStyle={{
                    width: 28,
                    height: 28,
                    textAlign: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    margin: "auto",
                    borderRadius: 5,
                  }}
                  renderSeparator={<span className="spaerate"></span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: 20,
                  marginRight: "20px",
                }}
              >
                <CustomeButton
                  //   disabled={veriload}
                  //   onClickHandler={() => verifyOtp_API()}
                  onClickHandler={() => setShowemailotp(false)}
                  buttonname={
                    veriload == false ? (
                      "Verify Email"
                    ) : (
                      <ClipLoader color={"#fff"} loading={true} size={12} />
                    )
                  }
                />
              </div>
            </div>
          )} */}
          <div style={{ display: "flex" }}>
            <Inputfiled
              type={showpass ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {showpass ? (
              <VisibilityOffIcon
                onClick={() => {
                  setShowpass(false);
                }}
                className="icoshow23"
              />
            ) : (
              <VisibilityIcon
                onClick={() => {
                  setShowpass(true);
                }}
                className="icoshow23"
              />
            )}
          </div>
          {passerroe && (
            <Errortext
              marginTop={5}
              title={
                "Password must be at least 8 characters long and contain at least  one special character !"
              }
            />
          )}
          <div style={{ display: "flex" }}>
            <Inputfiled
              type={showconf ? "text" : "password"}
              className="inputset"
              placeholder="Confrim Password"
              value={confpass}
              onChange={(e) => setConfpass(e.target.value)}
            />

            {showconf ? (
              <VisibilityOffIcon
                onClick={() => {
                  setShowconf(false);
                }}
                className="icoshow23"
              />
            ) : (
              <VisibilityIcon
                onClick={() => {
                  setShowconf(true);
                }}
                className="icoshow23"
              />
            )}
          </div>
          {confpasserrore && (
            <Errortext
              marginTop={5}
              title={"password and confirm password not matched!"}
            />
          )}

          <div style={{ textAlign: "center", marginTop: 20 }}>
            <CustomeButton
              onClickHandler={() => Validation()}
              buttonname={
                loading == false ? (
                  "Register"
                ) : (
                  <ClipLoader color={"#fff"} loading={loading} size={12} />
                )
              }
            />
          </div>
          {/* <div className="flexclass">
            <text>Already have an account?</text> */}
          <Link className="linkcalss" to={"/"}>
            Already have an account?
          </Link>
          {/* </div> */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Singup;
