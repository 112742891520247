import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Inputfiled from "../components/Inputfiled";
import Select from "react-select";
import { ADDDocumentAPI } from "../utils/ExportAPI";
import { SuccessToast } from "../utils/constant";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import Errortext from "../components/Errortext";
import { ToastContainer } from "react-toastify";
import CancelIcon from '@mui/icons-material/Cancel';
const Adddocument = () => {
  const User = useSelector((state) => state.user);
  const [typedoc, setTypedoc] = useState("null");
  const [docimg, setDocimg] = useState("");
  const [docname, setDocname] = useState("");
  const [loading, setLoading] = useState(false);
  const [docnameeeor, setDocnameeeor] = useState(false);
  const [docfileerroe, setDocfileerroe] = useState(false);
  const TypeData = [
    { value: "Aadhar", label: "Aadhar Card" },
    { value: "PAN", label: "PAN Card" },
    { value: "RC", label: "RC Card" },
    { value: "Drivelicance", label: "Driving license " },
    { value: "Passport", label: "Passport" },
  ];

  function DocumentValid() {
    if (docname?.length >= 1) {
      setDocnameeeor(false);
      if (docimg?.length >= 1 || docimg != "") {
        setDocfileerroe(false);
        setLoading(true);
        ADDDocumentAPI(User?.userid, typedoc, docname, docimg)
          .then((data) => {
            setLoading(false);
            setTypedoc("null");
            setDocimg("");
            setDocname("");
            SuccessToast("Document added successfully");
          })
          .catch((e) => console.log(e));
      } else {
        setDocfileerroe(true);
      }
    } else {
      setDocnameeeor(true);
    }
  }
  return (
    <div className="homediv">
      <Header backname={"Home"} pagename={"Add Document"} to={"/homepage"} />

      <div className="alicenter">
        <h3 style={{ textAlign: "center" }}>Add Document</h3>
        <br />

        <text className="lablet"> Select Document Type</text>
        <select
          style={{ backgroundColor: "#fff" }}
          className="selctarea"
          value={typedoc}
          onChange={(e) => setTypedoc(e.target.value)}
        >
          <option value={"null"}>Select Type</option>
          <option value={"Aadhar"}>Aadhar Card</option>
          <option value={"PAN"}>PAN Card</option>
          <option value={"RC"}>RC Card</option>
          <option value={"Drivelicance"}>Driving license Card</option>
          <option value={"Passport"}>Passport</option>
        </select>
        <br />
        <br />
        <div>
          {typedoc != "null" && (
            <div>
              <div>
                <Borview />
                <text className="mianlab">
                  {typedoc == "Aadhar"
                    ? "Aadhar Card"
                    : typedoc == "PAN"
                    ? "PAN Card"
                    : typedoc == "Drivelicance"
                    ? "Driving license Card"
                    : typedoc == "RC"
                    ? "RC Card"
                    : typedoc == "Passport" && "Passport"}
                </text>

                <div>
                  <text className="lablet">
                    {typedoc == "Aadhar"
                      ? "Aadhar"
                      : typedoc == "PAN"
                      ? "PAN"
                      : typedoc == "Drivelicance"
                      ? "Driving license"
                      : typedoc == "RC"
                      ? "RC"
                      : typedoc == "Passport" && "Passport"}{" "}
                    name
                  </text>
                  <Inputfiled
                    placeholder={
                      typedoc == "Aadhar"
                        ? "Aadhar"
                        : typedoc == "PAN"
                        ? "PAN"
                        : typedoc == "Drivelicance"
                        ? "Driving license"
                        : typedoc == "RC"
                        ? "RC"
                        : typedoc == "Passport" && "Passport"
                    }
                    value={docname}
                    onChange={(e) => setDocname(e.target.value)}
                  />
                  {docnameeeor && (
                    <Errortext
                      marginTop={5}
                      title={"Document name is required!"}
                    />
                  )}
                </div>
                <text className="lablet"> Upload Document</text>
                <UploadView
                  selectedImage={docimg}
                  onChange={(event) => {
                    setDocimg(event.target.files[0]);
                  }}
                  removedata={() => setDocimg("")}
                />
                {docfileerroe && (
                  <Errortext
                    marginTop={5}
                    title={"Document Image is required!"}
                  />
                )}
              </div>

              <br />
             
              <button
                type="submit"
                onClick={() => DocumentValid()}
                className="viewbttn"
              >
                {loading == false ? (
                  "Add"
                ) : (
                  <ClipLoader color={"#fff"} loading={loading} size={12} />
                )}
              </button>
            </div>
          )}
        </div>

        <br />
      </div>
      <ToastContainer />
      <div
        style={
          {
            position: "fixed",
            width: "100%",
            bottom: "0",
            // marginTop: typedoc == "null" ? "59vh" : "30vh",
          }
        }
      >
        <Footer className={"foterview"} />
      </div>
   
    </div>
  );
};

const UploadView = ({ selectedImage, removedata, onChange }) => (
  <div>
    {selectedImage && (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
       <div>
     
        <img
          alt="aadharcard"
          width={"150px"}
          height={"150px"}
     
          src={
            selectedImage?.type == "application/pdf"
              ? "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png"
              : URL.createObjectURL(selectedImage)
          }
        />
        <CancelIcon
          onClick={removedata}
          className="actioncion11"
          // className="viewbttn33"
          style={{ color: "#c00" }}
        />
        </div>
      
      </div>
    )}

    {/* <input
      type="file"
      name="myImage"
      accept="application/pdf,image/*"
      onChange={onChange}
    /> */}
    <input
      type="file"
      id="file"
      accept="application/pdf,image/*"
      onChange={onChange}
    />
    <label for="file" class="btn-2">
      upload
    </label>
  </div>
);

const Borview = () => (
  <div
    style={{ border: "0.5px solid #cccc", width: "100%", marginBottom: 15 }}
  ></div>
);
export default Adddocument;
